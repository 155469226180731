import { vuexName, VuexNames } from "../utils";

export enum IsUserPublishedMediaTypes {
  VIDEO = "video",
  AUDIO = "audio",
}
interface IsUserPublishedParams {
  userId: string;
  withModuleName?: boolean;
  gettersOrRootGetters: any;
}

export function isUserPublished(
  { userId, gettersOrRootGetters, withModuleName = true }: IsUserPublishedParams,
  mediaType: IsUserPublishedMediaTypes,
): boolean {
  const path = vuexName(
    mediaType === IsUserPublishedMediaTypes.VIDEO ? VuexNames.CALLING.GETTERS.GET_VIDEO_USER_IDS : VuexNames.CALLING.GETTERS.GET_AUDIO_USER_IDS,
    withModuleName,
  );
  const userIds: string[] = gettersOrRootGetters[path];
  return userIds.includes(userId);
}

export function isUserPublishedVideo(params: IsUserPublishedParams): boolean {
  return isUserPublished(params, IsUserPublishedMediaTypes.VIDEO);
}

export function isUserPublishedAudio(params: IsUserPublishedParams): boolean {
  return isUserPublished(params, IsUserPublishedMediaTypes.AUDIO);
}

export enum MediaUserPublishStatus {
  PUBLISHED = 1,
  UNPUBLISHED = 2,
}

export function updateUserIds(userIds: string[], { id, status }: { id: string; status: MediaUserPublishStatus }) {
  const index = userIds.findIndex((remoteId) => remoteId === id);
  if (status === MediaUserPublishStatus.UNPUBLISHED) {
    if (index > -1) {
      userIds.splice(index, 1);
    }
  } else if (index === -1) {
    userIds.push(id);
  }
}
