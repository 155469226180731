import { LessonGalleryItem } from "@/components/common/lesson-gallery/lesson-gallery";
import { IndependentLocales, TeacherClassGallery, TeamLocales } from "@/locales/localeid";
import { computed, defineComponent, ref } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import { Tooltip } from "ant-design-vue";
import { MicrophoneIcon } from "@/components/common";
import { PlusIcon, VideoCameraIcon, VideoCameraSlashIcon } from "@heroicons/vue/20/solid";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";
import { MediaDeviceTypes } from "@/utils/utils";
import { RoomStore, useRoomStore } from "@/hooks/use-room-store";
import { debounce } from "lodash";
import { StudentState } from "@/store/room/interface";
import { LoadingOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "StudentControls",
  props: ["isTeamMode"],
  components: {
    Tooltip,
    VideoCameraIcon,
    VideoCameraSlashIcon,
    MicrophoneIcon,
    PlusIcon,
    LoadingOutlined,
  },
  setup(props, { emit }) {
    const { getters, dispatch } = useStore();
    const { onlineStudentIdsNotInOneToOneMode, isAllOnlineStudentsVideoHidden, isAllOnlineStudentsAudioMuted, isAllOnlineStudentsDisabledPalette } =
      useRoomStore(RoomStore.Teacher);
    const { requestToggleStudentMediaDevices, requestToggleStudentPalette, requestSetStudentsBadge } = useSendWebSocketMsg();
    const isLoadingCamera = ref(false);
    const isLoadingMicrophone = ref(false);
    const isLoadingPalette = ref(false);
    const isLoadingBadge = ref(false);
    const loadingIndependent = ref(false);
    const stickerAllText = computed(() => fmtMsg(TeacherClassGallery.StickerAll));
    const unmuteAllText = computed(() => fmtMsg(TeacherClassGallery.UnmuteAll));
    const muteAllText = computed(() => fmtMsg(TeacherClassGallery.MuteAll));
    const enableAllText = computed(() => fmtMsg(TeacherClassGallery.EnableAll));
    const disableAllText = computed(() => fmtMsg(TeacherClassGallery.DisableAll));
    const showAllText = computed(() => fmtMsg(TeacherClassGallery.ShowAll));
    const hideAllText = computed(() => fmtMsg(TeacherClassGallery.HideAll));
    const students = computed<Array<StudentState>>(() => getters["teacherRoom/students"]);
    const callingUserIds = computed<Array<string>>(() => getters["calling/callingUserIds"]);

    const toggleDevice = async (deviceType: MediaDeviceTypes, isAllDeviceMuted: boolean) => {
      if (deviceType === MediaDeviceTypes.Camera) {
        isLoadingCamera.value = true;
      } else {
        isLoadingMicrophone.value = true;
      }
      const isMute = !isAllDeviceMuted;
      const studentIds = onlineStudentIdsNotInOneToOneMode.value;
      const payload = { mediaDeviceType: deviceType, isMute, studentIds };
      await requestToggleStudentMediaDevices(payload);
      if (deviceType === MediaDeviceTypes.Camera) {
        isLoadingCamera.value = false;
      } else {
        isLoadingMicrophone.value = false;
      }
    };

    const toggleVideo = debounce(() => toggleDevice(MediaDeviceTypes.Camera, isAllOnlineStudentsVideoHidden.value), 150);
    const toggleAudio = debounce(() => toggleDevice(MediaDeviceTypes.Microphone, isAllOnlineStudentsAudioMuted.value), 150);

    const onClickBadgeAll = async () => {
      isLoadingBadge.value = true;
      const onlineStudents = students.value.filter((s) => callingUserIds.value.includes(s.id)).map((s) => s.id);
      await requestSetStudentsBadge(onlineStudents);
      isLoadingBadge.value = false;
    };
    const onClickDisableAllPalette = async () => {
      isLoadingPalette.value = true;
      const currentPaletteStudent: StudentState | undefined = students.value.find((student) => student.isPalette);
      if (!currentPaletteStudent) return;

      await requestToggleStudentPalette({
        studentId: currentPaletteStudent.id,
        isEnable: false,
        isHelper: getters["teacher/isHelper"],
        // This button is only pressed during a normal session, so isOneOne is always false
        isOneOne: false,
      });
      isLoadingPalette.value = false;
    };
    const toggleIndependentMode = async () => {
      loadingIndependent.value = true;
      await dispatch("teacherTeaching/toggleIndependentMode");
      loadingIndependent.value = false;
    };
    const isIndependentMode = computed<boolean>(() => getters["classTeaching/isIndependentMode"]);
    const independentModeContent = computed<LessonGalleryItem[] | null>(() => {
      return getters["lesson/listReaderItemsOfCurrentExposure"];
    });
    const independentModeShown = computed<boolean>(() => {
      const isHelper = getters["teacher/isHelper"];
      if (isHelper) return false;
      return independentModeContent.value ? independentModeContent.value.length > 0 : false;
    });
    const independentModeDisabled = computed(() => props.isTeamMode || loadingIndependent.value);
    const independentButtonText = computed(() =>
      props.isTeamMode ? MsgPreventToggleIMWarning.value : isIndependentMode.value ? MsgStopIndependentMode.value : MsgStartIndependentMode.value,
    );

    //** Messages */
    const MsgTeamModeName = computed(() => fmtMsg(TeamLocales.TeamMode));
    const MsgPreventToggleIMWarning = computed(() =>
      fmtMsg(IndependentLocales.PreventToggleIndependentModeWarning, { modeName: MsgTeamModeName.value }),
    );
    const MsgStopIndependentMode = computed(() => fmtMsg(IndependentLocales.StopMode));
    const MsgStartIndependentMode = computed(() => fmtMsg(IndependentLocales.StartMode));
    return {
      onClickDisableAllPalette,
      onClickBadgeAll,
      toggleVideo,
      toggleAudio,
      isAllOnlineStudentsVideoHidden,
      isAllOnlineStudentsAudioMuted,
      isAllOnlineStudentsDisabledPalette,
      stickerAllText,
      unmuteAllText,
      muteAllText,
      enableAllText,
      disableAllText,
      showAllText,
      hideAllText,
      toggleIndependentMode,
      isIndependentMode,
      loadingIndependent,
      MsgStopIndependentMode,
      MsgStartIndependentMode,
      VideoCameraIcon,
      VideoCameraSlashIcon,
      independentModeShown,
      independentModeDisabled,
      independentButtonText,
      isLoadingBadge,
      isLoadingCamera,
      isLoadingMicrophone,
      isLoadingPalette,
    };
  },
});
