import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createVNode as _createVNode, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "aspect-w-16 aspect-h-9 bg-white" }
const _hoisted_2 = { class: "absolute inset-0 flex justify-center items-center" }
const _hoisted_3 = {
  key: 1,
  class: "animate-spin h-6 w-6 text-slate-600",
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConnectAvatar = _resolveComponent("ConnectAvatar")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!
  const _component_Cog6ToothIcon = _resolveComponent("Cog6ToothIcon")!
  const _component_ArrowsPointingInIcon = _resolveComponent("ArrowsPointingInIcon")!
  const _component_DeviceSettingsModal = _resolveComponent("DeviceSettingsModal")!
  const _component_XMarkIcon = _resolveComponent("XMarkIcon")!
  const _component_NameTag = _resolveComponent("NameTag")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["relative text-slate-600 rounded-xl w-full overflow-hidden group", [_ctx.isAvatarShown ? '' : 'shadow']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("figure", null, [
        _createElementVNode("div", _hoisted_2, [
          (!_ctx.isAvatarShown)
            ? (_openBlock(), _createBlock(_component_ConnectAvatar, {
                key: 0,
                src: _ctx.helperAvatar,
                alt: _ctx.helperInfo?.name
              }, null, 8, ["src", "alt"]))
            : (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[8] || (_cache[8] = [
                _createElementVNode("circle", {
                  class: "opacity-25",
                  cx: "12",
                  cy: "12",
                  r: "10",
                  stroke: "currentColor",
                  "stroke-width": "4"
                }, null, -1),
                _createElementVNode("path", {
                  class: "opacity-75",
                  fill: "currentColor",
                  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                }, null, -1)
              ])))
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(['w-full h-full object-cover', !_ctx.isAvatarShown && 'hidden']),
          id: _ctx.helperId
        }, null, 10, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([[_ctx.isAvatarShown ? 'opacity-0' : ''], "absolute top-2 left-1/2 -translate-x-1/2 flex space-x-1.5 group-hover:opacity-100 transition-opacity"])
    }, [
      (_ctx.isHelper)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onHelperToggleCamera && _ctx.onHelperToggleCamera(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            }, [
              (_ctx.isCameraLocking)
                ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
                    key: 0,
                    class: "animate-spin"
                  }))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isVideoPublished ? _ctx.VideoCameraIcon : _ctx.VideoCameraSlashIcon), {
                    key: 1,
                    class: "h-4 w-4"
                  }))
            ]),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onHelperToggleMicro && _ctx.onHelperToggleMicro(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer disabled:cursor-wait"
            }, [
              (_ctx.isMicrophoneLocking)
                ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
                    key: 0,
                    class: "animate-spin"
                  }))
                : (_openBlock(), _createBlock(_component_MicrophoneIcon, {
                    key: 1,
                    "icon-class": "h-4 w-4",
                    enabled: _ctx.isAudioPublished
                  }, null, 8, ["enabled"]))
            ]),
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openDeviceSettingsModal && _ctx.openDeviceSettingsModal(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
            }, [
              _createVNode(_component_Cog6ToothIcon, { class: "h-4 w-4" })
            ]),
            _createElementVNode("div", {
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickMinimized && _ctx.onClickMinimized(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
            }, [
              _createVNode(_component_ArrowsPointingInIcon, { class: "h-4 w-4" })
            ]),
            _createVNode(_component_DeviceSettingsModal, {
              visible: _ctx.isDeviceSettingsModalShown,
              "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.isDeviceSettingsModalShown) = $event)),
              isMicEnabled: _ctx.isAudioPublished,
              isCamEnabled: _ctx.isVideoPublished,
              onUpdateCamOpen: _ctx.updateCamOpen,
              onUpdateMicOpen: _ctx.updateMicOpen
            }, null, 8, ["visible", "isMicEnabled", "isCamEnabled", "onUpdateCamOpen", "onUpdateMicOpen"])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", {
              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleHelperVideo(!_ctx.isTeacherAllowStudentSeeHelperVideo))),
              class: _normalizeClass([[_ctx.toggleHelperVideoLoading ? 'pointer-events-none' : 'cursor-pointer'], "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"])
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isTeacherAllowStudentSeeHelperVideo ? _ctx.EyeIcon : _ctx.EyeSlashIcon), { class: "h-4 w-4" }))
            ], 2),
            _createElementVNode("div", {
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.onClickMinimized && _ctx.onClickMinimized(...args))),
              class: "h-7 w-7 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors cursor-pointer"
            }, [
              _createVNode(_component_ArrowsPointingInIcon, { class: "h-4 w-4" })
            ]),
            _createElementVNode("div", {
              class: "h-7 w-7 bg-red-800/80 text-white flex items-center justify-center rounded-md hover:bg-red-800 hover:shadow transition-colors cursor-pointer",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onRemoveHelper && _ctx.onRemoveHelper(...args)))
            }, [
              _createVNode(_component_XMarkIcon, { class: "h-4 w-4" })
            ])
          ], 64))
    ], 2),
    _createVNode(_component_NameTag, {
      name: _ctx.helperInfo?.name,
      "is-muted": !_ctx.isAudioPublished,
      class: "absolute bottom-2 left-3"
    }, null, 8, ["name", "is-muted"])
  ], 2)), [
    [_vShow, _ctx.helperInfo?.id]
  ])
}