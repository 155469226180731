import { ActionContext } from "vuex";
import _ from "lodash";
import { notification } from "ant-design-vue";
import { fmtMsg } from "vue-glcommonui";
import { InClassStatus, lowBandWidthPoint, NetworkQualityPayload, SHOW_LOW_BANDWIDTH_NOTIFICATION_DURATION } from "@/store/room/interface";
import { TeacherRoomState } from "@/store/room/teacher/state";
import { HelperLocales, LowBandWidthLocales, TeacherClassLocale } from "@/locales/localeid";
import { MainRoles, RemoteTeachingService } from "@/services";
import { CallingUserStatus } from "@/store/calling/mutations";
import { ConnectionDisconnectedReason, ConnectionState } from "agora-rtc-sdk-ng";
import { Logger } from "@/utils/logger";
import { PostLocalUserReconnectedPayload } from "@/store/calling/actions";
import router from "@/router";
import { Paths } from "@/utils/paths";
import { isSameUserJoined, needTriggerManualReconnect } from "@/utils/utils";
import { notifySameUserJoined } from "@/utils/notifications";
import { MediaUserPublishStatus } from "@/store/calling/utils";
import { vuexName, VuexNames } from "@/store/utils";

export const callingUtils = ({ commit, dispatch, state, rootState }: ActionContext<TeacherRoomState, any>) => {
  //* Teacher joining class is "Teacher" or "Helper" */
  const { isHelper: joinAsHelper } = rootState.teacher;
  return {
    remoteUserJoined: async (id: string) => {
      Logger.log("USER_JOINED", id);
      commit("calling/setCallingUserIds", { id, status: CallingUserStatus.JOINED }, { root: true });
      const student = state.students.find((student) => student.id === id);
      if (student) {
        notification.info({ message: fmtMsg(TeacherClassLocale.StudentJoinedClass, { studentName: student.englishName }) });
      } else if (joinAsHelper) {
        commit("setTeacherDisconnected", false);
      }
      await dispatch("calling/callingUpdateAudioAndVideoFeed", {}, { root: true });
    },
    remoteUserLeft: async (id: string, reason?: string) => {
      Logger.log("USER_LEFT", id);
      commit("calling/setCallingUserIds", { id, status: CallingUserStatus.LEFT }, { root: true });
      await dispatch("calling/callingUpdateAudioAndVideoFeed", {}, { root: true });
      if (state.isSessionEnded || !state.info?.id || !rootState.teacher?.info.id) return;
      let remoteUserRole = MainRoles.TEACHER;
      const student = state.students.find((student) => student.id === id);
      if (student) {
        remoteUserRole = MainRoles.STUDENT;
        const { status, englishName } = student;
        if (status !== InClassStatus.LEFT) {
          notification.warn({ message: fmtMsg(TeacherClassLocale.StudentDroppedOutClass, { studentName: englishName }) });
        }
        dispatch("forceOutHelperOneToOne");
      } else if (joinAsHelper) {
        commit("setTeacherDisconnected", true);
      } else {
        commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
        remoteUserRole = MainRoles.HELPER;
        notification.warn({ message: fmtMsg(HelperLocales.Disconnected, { name: state.helper?.name }) });
        dispatch("forceOutHelperOneToOne");
      }
      RemoteTeachingService.putRemoteUserDisconnected({
        remoteUserRole,
        remoteUserId: id,
        sessionId: state.info.id,
        localUserId: rootState.teacher.info.id,
      });
    },
    localUserConnectionChanged: async (curState: ConnectionState, prevState: ConnectionState, reason: ConnectionDisconnectedReason | undefined) => {
      Logger.log("AGORA: Connection Changed", { curState, prevState }, reason);
      if (isSameUserJoined(curState, reason)) {
        await dispatch("resetClass", false);
        notifySameUserJoined();
        return await router.push(Paths.Teacher);
      }
      const sessionId = state.info?.id;
      // localTeacherId can be teacher's id or helper's id
      const localTeacherId = rootState.teacher?.info?.id;
      if (!sessionId || !localTeacherId) return;
      commit(
        "calling/setCallingUserIds",
        { id: localTeacherId, status: curState === "CONNECTED" ? CallingUserStatus.JOINED : CallingUserStatus.LEFT },
        { root: true },
      );
      await dispatch(`${curState === "CONNECTED" || curState === "CONNECTING" ? "setOnline" : "setOffline"}`);
      if (curState === "CONNECTED") {
        const payload: PostLocalUserReconnectedPayload = {
          localUserRole: joinAsHelper ? MainRoles.HELPER : MainRoles.TEACHER,
          localUserId: localTeacherId,
        };
        await dispatch("calling/postLocalUserReconnected", payload, { root: true });
      }
      // Manually reconnecting the Agora Room if reconnecting mechanism is not working
      if (needTriggerManualReconnect(curState)) {
        if (state.manager) {
          await state.manager.agoraClient?.leaveChannel();
          await state.manager.manualReconnectAgoraRoom();
        }
      }
    },
    handleException: async () => {
      //
    },
    bandwidthUpdateBasedOnAgora: async (payload: NetworkQualityPayload) => {
      const { uplinkNetworkQuality, downlinkNetworkQuality } = payload;
      if (uplinkNetworkQuality < lowBandWidthPoint || downlinkNetworkQuality < lowBandWidthPoint) {
        rootState.app.isLowBandWidth && (await dispatch("setLowBandWidth", false, { root: true }));
      } else if (!rootState.app.isLowBandWidth) {
        const bandwidthLower = uplinkNetworkQuality > downlinkNetworkQuality ? uplinkNetworkQuality.toString() : downlinkNetworkQuality.toString();
        await dispatch("setLowBandWidth", true, { root: true });
        await RemoteTeachingService.putTeacherBandwidth(bandwidthLower, joinAsHelper);
        notification.warning({
          key: joinAsHelper ? state.helper?.id : state.teacher?.id,
          message: fmtMsg(LowBandWidthLocales.LocalUserLowBandWidth),
          duration: SHOW_LOW_BANDWIDTH_NOTIFICATION_DURATION,
        });
      }
      let hasChange = false;
      const studentIdNetworkQuality = state.manager?.agoraClient?._client?.getRemoteNetworkQuality();
      const listStudentLowBandWidthState = [...state.listStudentLowBandWidth];
      if (_.isEmpty(studentIdNetworkQuality)) return;
      for (const studentId in studentIdNetworkQuality) {
        const networkQuality: NetworkQualityPayload = studentIdNetworkQuality[studentId];
        const { uplinkNetworkQuality, downlinkNetworkQuality } = networkQuality;
        if (uplinkNetworkQuality < lowBandWidthPoint || downlinkNetworkQuality < lowBandWidthPoint) {
          const studentIdExistingIndex = listStudentLowBandWidthState.findIndex((id) => studentId === id);
          if (studentIdExistingIndex > -1) {
            hasChange = true;
            listStudentLowBandWidthState.splice(studentIdExistingIndex, 1);
          }
        } else {
          const studentIdExisting = listStudentLowBandWidthState.find((id) => studentId === id);
          if (!studentIdExisting) {
            hasChange = true;
            listStudentLowBandWidthState.push(studentId);
          }
        }
      }
      if (!hasChange) return;
      await dispatch("setListStudentLowBandWidth", listStudentLowBandWidthState);
      for (const id of listStudentLowBandWidthState) {
        const name = state.students.find((student) => student.id === id)?.englishName;
        if (name && typeof name === "string") {
          notification.info({
            key: id,
            message: fmtMsg(LowBandWidthLocales.RemoteUserLowBandWidth, { name }),
          });
        }
      }
    },
    handleUserPublishedVideo: async ({ id, status }: { id: string; status: MediaUserPublishStatus }) => {
      commit(vuexName(VuexNames.CALLING.COMMITS.SET_VIDEO_USER_IDS), { id, status }, { root: true });
    },
    handleUserPublishedAudio: async ({ id, status }: { id: string; status: MediaUserPublishStatus }) => {
      commit(vuexName(VuexNames.CALLING.COMMITS.SET_AUDIO_USER_IDS), { id, status }, { root: true });
    },
  };
};
