import { GetterTree } from "vuex";
import { CallingState } from "./state";
import { defineVuexName, VuexNames } from "../utils";

const getters: GetterTree<CallingState, any> = {
  callingUserIds: (state: CallingState): string[] => {
    return state.callingUserIds;
  },
  enabledAgoraNoiseCancelling: (state: CallingState): boolean => {
    return state.enabledAgoraNoiseCancelling;
  },
  checkCallingHasUserById: (state: CallingState): ((userId: string) => boolean) => {
    return (userId: string) => state.callingUserIds.findIndex((id) => id === userId) > -1;
  },
  postLocalUserReconnectedIntervalId: (state: CallingState): number | undefined => {
    return state.postLocalUserReconnectedIntervalId;
  },
  [defineVuexName(VuexNames.CALLING.GETTERS.GET_VIDEO_USER_IDS)](s: CallingState) {
    return s.videoUserIds;
  },
  [defineVuexName(VuexNames.CALLING.GETTERS.GET_AUDIO_USER_IDS)](s: CallingState) {
    return s.audioUserIds;
  },
};

export default getters;
