// from RemoteTeachingHub.cs
export enum StudentWSCommand {
  JOIN_SESSION = "StudentJoinSession",
  TOGGLE_VIDEO = "StudentToggleVideo",
  TOGGLE_AUDIO = "StudentToggleAudio",
  RAISE_HAND = "StudentRaiseHand",
  LIKE = "StudentLike",
  ANSWER = "StudentAnswerInteractive",
  STUDENT_ACTION_GAME = "StudentActionGame",
  LEAVE_SESSION = "StudentLeaveSession",
  TOGGLE_SHAPE = "StudentToggleShape",
  CHANGE_INDEPENDENT_ITEM = "StudentSelectIndependentItem",
  STUDENT_HEART_BEAT = 'StudentHeartBeat',
}
