import { JoinSessionModel } from "@/models/join-session.model";
import { UpdateLessonAndUnitModel } from "@/models/update-lesson-and-unit.model";
import {
  CheckSessionStartResp,
  FetchOngoingSessionModel,
  FetchTeamsReqModel,
  FetchTeamsResModel,
  PostLocalUserReconnectedReqModel,
  PutRemoteUserDisconnectedReqModel,
  PutTeamsReqModel,
  PutTeamsResModel,
  PutToggleTeamModeReqModel,
  PutToggleTeamModeResModel,
  RemoteTeachingServiceInterface,
  TeacherUpdateCanvasObjectRequestModel,
  TeacherUpdateMediaStateRequestModel,
  transformOneToOneDataServeTeacher,
} from "@/services";
import { GLServiceBase } from "vue-glcommonui";
import {
  GenerateTokenResponse,
  GetMinimalSessionDtoResponse,
  NextSessionResponse,
  SessionReplacementResponse,
  SessionStateResponse,
  FetchSessionDtoResModel,
  UnitAndLessonResponse,
  SurveyResModel,
  TeacherToggleStudentMediaDevicesResp,
  ToggleStudentPaletteReq,
  ToggleStudentPalettesResp,
  SetStudentsBadgeResp,
  ClearStudentRaisingHandResp,
  SetStudentsBadgeReq,
  ToggleStudentMediaDevicesReq,
  BaseResponse,
} from "./model";
import { ToggleAllTargetsModel } from "@/ws";
class GLRemoteTeachingService extends GLServiceBase<any, any> implements RemoteTeachingServiceInterface {
  serviceRoute = { prefix: "remote/v1" };

  fetchSessionDataAsTeacher(deviceId: string): Promise<FetchSessionDtoResModel> {
    return this.get("teacher/online-session", { deviceId }).then((res) => transformOneToOneDataServeTeacher(res as FetchSessionDtoResModel));
  }

  teacherStartClassRoom(startModel: JoinSessionModel): Promise<any> {
    return this.create("teacher/join-session", { ...startModel });
  }

  teacherEndClassRoom(roomId?: string, markAsComplete?: boolean): Promise<any> {
    if (!roomId) return Promise.resolve(null);
    return this.update("teacher/end-class/" + roomId + `?MarkAsComplete=${markAsComplete?.toString()}`);
  }

  studentGetRoomInfo(childId: string, deviceId: string): Promise<FetchSessionDtoResModel> {
    this.serviceRoute = { prefix: "remote/v2" };
    const resolution = screen.width * window.devicePixelRatio + "x" + screen.height * window.devicePixelRatio;
    const promise = this.get(`student/join-session`, {
      studentId: childId,
      resolution,
      deviceId,
    });
    this.serviceRoute = { prefix: "remote/v1" };
    return promise as Promise<FetchSessionDtoResModel>;
  }

  checkSessionStart(studentId: string, deviceId: string): Promise<CheckSessionStartResp> {
    const promise = this.get(`student/is-online-session`, {
      studentId,
      deviceId,
    });
    return promise as Promise<CheckSessionStartResp>;
  }

  putTeacherBandwidth(bandwidth: string, isHelper: boolean): Promise<any> {
    const resolution = screen.width * window.devicePixelRatio + "x" + screen.height * window.devicePixelRatio;
    let apiUrl = isHelper ? "logs/helper" : "logs/teacher";
    apiUrl = `${apiUrl}?bandwidth=${bandwidth}&resolution=${resolution}&`;
    return this.update(apiUrl);
  }

  putStudentBandwidth(studentId: string, bandwidth: string): Promise<any> {
    const resolution = window.screen.width * window.devicePixelRatio + "x" + window.screen.height * window.devicePixelRatio;
    return this.update(`logs/student/${studentId}?bandwidth=${bandwidth}&resolution=${resolution}&`);
  }

  acceptPolicy(role: string): Promise<any> {
    return this.get(`policy/${role}/is-accepted`);
  }

  submitPolicy(role: string): Promise<any> {
    return this.create(`policy/${role}/accept/true`);
  }

  getLinkStoryDictionary(unitId: number, lessonId: number): Promise<any> {
    return this.get(`student/story-dictionary/unit/${unitId}/lesson/${lessonId}`);
  }

  getListLessonByUnit(classId: string, groupId: string, unit: number): Promise<UnitAndLessonResponse> {
    return this.get(`lesson-plan/sequence/class/${classId}/group/${groupId}/unit/${unit}`);
  }

  getStudentNextSession(listIds: string[]): Promise<Array<NextSessionResponse>> {
    let studentId = "";
    listIds.map((id: string, index: number) => {
      if (index != 0) {
        studentId += "&";
      }
      studentId += "studentId=" + id;
    });
    return this.get(`student/next-session?${studentId}&`);
  }
  getMinimalSessionDto(sessionId: string, studentId?: string): Promise<GetMinimalSessionDtoResponse> {
    return this.get(`rooms/${sessionId}/minimal?studentId=${studentId}&`);
  }

  teacherDrawLine(brushStrokes: string, sessionId: string, teacherUseOnly: boolean): Promise<any> {
    return this.create(`draw/teacher/line`, {
      brushesStroke: brushStrokes,
      sessionId: sessionId,
      teacherUseOnly,
    });
  }
  teacherAddShape(shapes: string[], sessionId: string, teacherUseOnly: boolean): Promise<any> {
    return this.create(`draw/teacher/shape`, {
      shapes: shapes,
      sessionId: sessionId,
      teacherUseOnly,
    });
  }
  generateOneToOneToken(roomId?: string, studentId?: string): Promise<GenerateTokenResponse> {
    let api = `zoom/generate-one-to-one-token?roomId=${roomId}`;
    if (studentId) {
      api += `&studentId=${studentId}`;
    }
    return this.get(`${api}&`);
  }

  teacherUpdateLessonAndUnit(model: UpdateLessonAndUnitModel): Promise<any> {
    return this.update("rooms/update-lesson-and-unit", model);
  }

  fetchSessionDtoById(sessionId: string): Promise<FetchSessionDtoResModel> {
    return this.get(`rooms/${sessionId}`);
  }

  teacherGetSessionById(sessionId: string): Promise<FetchSessionDtoResModel> {
    return this.get(`rooms/${sessionId}`);
  }
  sessionReplacementAccept4Teacher(deviceId: string): Promise<SessionReplacementResponse> {
    return this.update(`teacher/update-new-device?deviceId=${deviceId}`);
  }
  sessionReplacementAccept4Helper(deviceId: string): Promise<SessionReplacementResponse> {
    return this.update(`helper/update-new-device?deviceId=${deviceId}`);
  }
  getSessionState(sessionId: string): Promise<SessionStateResponse> {
    return this.get(`rooms/media-state/${sessionId}`);
  }
  teacherUpdateMediaState(model: TeacherUpdateMediaStateRequestModel): Promise<boolean> {
    return this.update(`teacher/media-state`, model, undefined, { activeRequestKey: "TeacherUpdateMediaState" });
  }
  //** Informing to the SERVER that remote user is disconnected */
  putRemoteUserDisconnected(params: PutRemoteUserDisconnectedReqModel) {
    const { localUserId, sessionId, remoteUserId, remoteUserRole } = params;
    return this.update(`${remoteUserRole}/on-disconnect`, { sessionId, userDisconnectedId: remoteUserId, userRequestId: localUserId });
  }
  postLocalUserReconnected(params: PostLocalUserReconnectedReqModel) {
    const { localUserId, sessionId, localUserRole } = params;
    return this.create(`${localUserRole}/reconnect?sessionId=${sessionId}&userId=${localUserId}`);
  }
  fetchTeams(params: FetchTeamsReqModel): Promise<FetchTeamsResModel> {
    return this.get(`team/session/${params.sessionId}`);
  }
  putToggleTeamMode(params: PutToggleTeamModeReqModel): Promise<PutToggleTeamModeResModel> {
    const { isOpen, sessionId } = params;
    return this.update(`team/session/${sessionId}/toggle/${params.isOpen}`);
  }
  putTeams(params: PutTeamsReqModel): Promise<PutTeamsResModel> {
    const { teams, sessionId } = params;
    return this.update(`team/session/${sessionId}/upsert/`, params.teams);
  }
  fetchAgoraNoiseCancellingExtensionStatus(): Promise<boolean> {
    return this.get("rooms/IsEnableNoiseCanceling");
  }
  updateCallingQualitySurvey(params: SurveyResModel) {
    const { sessionId, isGoodQuality, feedback } = params;
    return this.create(`survey/calling-quality-survey`, { sessionId, isGoodQuality, feedback });
  }
  updateCanvasObject(params: TeacherUpdateCanvasObjectRequestModel): Promise<boolean> {
    return this.create(`draw/teacher/canvas-object`, params);
  }
  toggleStudentMediaDevices(params: ToggleStudentMediaDevicesReq): Promise<TeacherToggleStudentMediaDevicesResp> {
    return this.create(`teacher/toggle-student-media-devices`, params);
  }
  toggleStudentPalette(params: ToggleStudentPaletteReq): Promise<ToggleStudentPalettesResp> {
    return this.create(`teacher/toggle-student-palette`, params);
  }
  setStudentsBadge(params: SetStudentsBadgeReq): Promise<SetStudentsBadgeResp> {
    return this.create(`teacher/set-badge`, params);
  }
  clearStudentRaisingHand(sessionId: string, studentId: string): Promise<ClearStudentRaisingHandResp> {
    return this.create(`teacher/clear-raising-hand/${studentId}/session/${sessionId}`);
  }
  toggleAllShape(params: ToggleAllTargetsModel): Promise<BaseResponse<boolean>> {
    return this.create(`teacher/toggle-all-shapes`, params);
  }
  fetchOngoingSession(): Promise<BaseResponse<FetchOngoingSessionModel | null>> {
    return this.get("teacher/ongoing-session");
  }
}

export const RemoteTeachingService = new GLRemoteTeachingService();
