import { DeviceSettingsModal } from "@/components/common";
import { brushstrokesRender } from "@/components/common/annotation-view/components/brush-strokes";
import { renderPolylineAsLaserOnCanvas } from "@/components/common/annotation-view/components/laser-path";
import { renderPolylineAsLineOnCanvas } from "@/components/common/annotation-view/components/pencil-path";
import ToolsCanvas from "@/components/common/annotation/tools/tools-canvas.vue";
import TextControl from "@/components/common/annotation/text-control/text-control.vue";
import { useFabricObject } from "@/hooks/use-fabric-object";
import { usePointerStyle } from "@/hooks/use-pointer-style";
import { useWhiteboardMediaState } from "@/hooks/use-whiteboard-media-state";
import { TeacherClass, TeacherClassLocale } from "@/locales/localeid";
import { MediaType, SendRealtimePenMessage } from "@/models";
import { FabricUpdateType, LastFabricUpdated, Pointer, UserShape } from "@/store/annotation/state";
import { Exposure, ExposureItemMedia, ExposureItemMediaImage } from "@/store/lesson/state";
import { Colors, MAX_ZOOM_RATIO, MIN_ZOOM_RATIO, ZOOM_STEP } from "@/utils/constant";
import { Logger } from "@/utils/logger";
import {
  ALTERNATE_AUDIO_ID,
  ALTERNATE_VIDEO_ID,
  createGuid,
  DEFAULT_ALTERNATE_CONTENT_VOLUME,
  DefaultCanvasDimension,
  generateRealtimeLineId,
  isRotatedLPSlide,
  Mode,
  Tools,
} from "@/utils/utils";
import { addShape } from "@/views/teacher-class/components/whiteboard-palette/components/add-shape";
import { CloseOutlined, DownOutlined, UpOutlined } from "@ant-design/icons-vue";
import { EyeIcon, EyeSlashIcon, MagnifyingGlassMinusIcon, MagnifyingGlassPlusIcon, ViewfinderCircleIcon } from "@heroicons/vue/24/outline";
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { useScroll } from "@vueuse/core";
import { Button, Modal, Popover, Radio, Space, Switch, Tooltip } from "ant-design-vue";
import { fabric } from "fabric";
import { computed, defineComponent, nextTick, onMounted, onUnmounted, PropType, reactive, Ref, ref, watch } from "vue";
import { fmtMsg, LoginInfo } from "vue-glcommonui";
import VuePdfEmbed from "vue-pdf-embed";
import { useStore } from "vuex";
import { useFocusWord } from "@/hooks/use-focus-word";
import { useWhiteboard } from "@/hooks/use-whiteboard";
import { initCanvas, initConf } from "@/hooks/use-canvas";
import { useCanvasObjectUpdate } from "@/hooks/use-canvas-object-update";
import {
  FbObjects,
  FbEvents,
  findLatestCreatedItem,
  getAllObjectsExceptBackgroundAndTargets,
  ConnectObjectTypes,
  FbObjectOrigins,
  getAllUndoableObjects,
} from "@/utils/fabric-utils";
import { useHandleObjects } from "@/hooks/use-handle-objects";
import { TeacherState } from "@/store/room/interface";
import { useObjectState } from "@/hooks/use-object-state";
import { useKeystrokeOnCanvas } from "@/hooks/use-keystroke-on-canvas";
import { DEFAULT_FONT_SIZE, Fonts } from "@/components/common/annotation/text-control/text-control";
import { useImageLoader } from "@/hooks/use-image-loader";
import { useSendWebSocketMsg } from "@/hooks/use-send-websocket-msg";
import { CustomPropertiesForTarget, useTarget } from "@/hooks/use-target";
import { useActiveElement } from "@vueuse/core";
import { useClassTeaching } from "@/hooks/use-class-teaching";
import { debounce, throttle } from "lodash";
export enum Cursor {
  Default = "default",
  Text = "text",
}
export interface ControlTextState {
  fontSize?: number;
  fill?: string;
  fontFamily?: string;
}
export const ToolsBlockClickOnTarget = [Tools.TextBox, Tools.Laser, Tools.Pen];

const DIFF_BETWEEN_POINT = 6;
const DEFAULT_ZOOM_PERCENT = 100;
const SCALE_RATIO = 1;
export default defineComponent({
  name: "WhiteboardPaletteComponent",
  props: {
    image: Object as PropType<ExposureItemMediaImage>,
    id: String,
    name: String,
  },
  components: {
    DeviceSettingsModal,
    ToolsCanvas,
    TextControl,
    Space,
    Button,
    VuePdfEmbed,
    RadioButton: Radio.Button,
    RadioGroup: Radio.Group,
    DownOutlined,
    UpOutlined,
    CloseOutlined,
    Modal,
    Popover,
    MagnifyingGlassMinusIcon,
    MagnifyingGlassPlusIcon,
    Switch,
    EyeIcon,
    EyeSlashIcon,
    ViewfinderCircleIcon,
    Tooltip,
    InformationCircleIcon,
  },
  setup(props) {
    let canvas: any;
    // * group variable is used to store the group (an element of fabric.js) containing the image and the annotations (targets)
    let group: any;
    let point: any;
    const toolNames: string[] = Object.values(Tools);
    const toolSelected: Ref<Tools> = ref(Tools.Laser);
    const strokeColor: Ref<string> = ref(Colors.Black);
    const { dispatch, commit, getters } = useStore();
    const { videoRef, audioRef, debounceSendApiUpdateMediaState, toggleMediaCanPlay } = useWhiteboardMediaState();
    const isBlockClickOnTarget = computed(() => ToolsBlockClickOnTarget.includes(toolSelected.value));
    const { handlePressRemoveIcon, handleEditObjectFromCanvas, handleRemoveObjectFromCanvas } = useCanvasObjectUpdate();
    const { isWbVisible } = useWhiteboard();
    const { lastUpdatedFabricObject, lastDeletedFabricObjectId, lines, linesOne, shapes, shapesOne, texts } = useObjectState();
    const { resetReloadKey, reloadKey, handleImageLoadError } = useImageLoader();
    const {
      createTextBox,
      onTextBoxEdited,
      onObjectModified,
      displayFabricItems,
      isEditing,
      onObjectCreated,
      nextColor,
      updateSelectedObjectStyles,
      FontLoader,
      displayCreatedItem,
      displayModifiedItem,
      onObjectRemoved,
      onObjectSelected,
      onSelectionChanged,
      updateNextStyles,
      isGroup,
      isPath,
      lineObjectId,
    } = useFabricObject();
    useKeystrokeOnCanvas();
    const { removeTextBoxObjectsFromCanvas, removeShapeObjectsFromCanvas } = useHandleObjects();
    const { requestClearBoard } = useSendWebSocketMsg();
    const { initializeTargets, handleClickToggleAllTargetsBtn, isAllTargetsOnCanvasVisible, isAllTargetsOnCanvasTransparent } = useTarget({
      isBlockClickOnTarget,
    });
    const { sendRequestUpdateCanvasObjectsConsideredShapes, addCircle, addSquare } = addShape();
    const { isSocketAttemptedReconnecting } = useClassTeaching();
    const whiteBoardRef = ref<HTMLDivElement>();
    const currentExposureItemMedia = computed<ExposureItemMedia | undefined>(() => getters["lesson/currentExposureItemMedia"]);
    const isImgProcessing = computed(() => getters["annotation/isImgProcessing"]);
    const currentExposure = computed<Exposure | undefined>(() => getters["lesson/currentExposure"]);
    const { pointerStyle } = usePointerStyle(whiteBoardRef);
    const isPointerMode = computed(() => getters["annotation/isPointerMode"]);
    const isTeacherUseOnly = computed(() => getters["teacherRoom/isTeacherUseOnly"]);
    const { focusWordContent } = useFocusWord();
    const teacher = computed<TeacherState | undefined>(() => getters["teacherRoom/teacher"]);
    const studentOneAndOneId = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const isHelper = computed<boolean>(() => getters["teacher/isHelper"]);
    const loginInfo: LoginInfo = getters["auth/getLoginInfo"];
    const lineId: Ref<string> = ref(generateRealtimeLineId());
    const zoomRatio = ref(MIN_ZOOM_RATIO);
    const strokeWidth: Ref<number> = ref(2);
    const modeAnnotation: Ref<number> = ref(-1);
    const firstLoadImage: Ref<boolean> = ref(false);
    const firstTimeLoadStrokes: Ref<boolean> = ref(false);
    const firstTimeLoadShapes: Ref<boolean> = ref(false);
    const firstTimeLoadOneToOneShapes: Ref<boolean> = ref(false);
    const video = ref<HTMLVideoElement | null>(null);
    const audio = ref<HTMLVideoElement | null>(null);
    const isDrawing: Ref<boolean> = ref(false);
    const prevPoint: Ref<Pointer | undefined> = ref(undefined);
    const isMouseOver: Ref<boolean> = ref(false);
    const isMouseOut: Ref<boolean> = ref(false);
    const studentDisconnected = computed<boolean>(() => getters["studentRoom/isDisconnected"]);
    const teacherDisconnected = computed<boolean>(() => getters["teacherRoom/isDisconnected"]);
    const isShowPreviewCanvas = computed(() => getters["lesson/isShowPreviewCanvas"]);
    const sessionZoomRatio = computed(() => getters["lesson/zoomRatio"]);
    const prevZoomRatio = ref(1);
    const prevCoords = ref({ x: 0, y: 0 });
    const prevPdfScrollProgress = ref(0);
    const zoomPercentage = ref(DEFAULT_ZOOM_PERCENT);
    const prevLineId = ref("");
    const diff = ref(0);
    const isMouseMoveOut = ref(true);
    const pointsSkipped = ref<Array<Pointer>>([]);
    const pencilPath = computed(() => getters["annotation/pencilPath"]);
    const laserPath = computed(() => getters["annotation/laserPath"]);
    const imgCoords = computed(() => getters["lesson/imgCoords"]);
    const ShowWhiteboardText = computed(() => fmtMsg(TeacherClassLocale.ShowWhiteboard));
    const HideWhiteboardText = computed(() => fmtMsg(TeacherClassLocale.HideWhiteboard));
    const PreviewTargetText = computed(() => fmtMsg(TeacherClassLocale.PreviewTarget));
    const WarningAudioTagText = computed(() => fmtMsg(TeacherClassLocale.WarningAudioTag));
    const WarningVideoTagText = computed(() => fmtMsg(TeacherClassLocale.WarningVideoTag));
    const isEmptyExposure = computed(() => currentExposure.value && !currentExposureItemMedia.value);
    const isWhiteboardShrink = computed(() => (!currentExposure.value && !isWbVisible.value) || (isEmptyExposure.value && !isWbVisible.value));
    const isTextBoxSelected = computed<boolean>(() => toolSelected.value === Tools.TextBox);
    const controlTextState = reactive({
      fontSize: DEFAULT_FONT_SIZE,
      fill: Colors.Black,
      fontFamily: Fonts.DidactGothic,
    });
    // ** This variable is used to prevent the watcher from being triggered when the "controlTextState" is updated */
    let isControlTextStateWatcherActive = true;
    const updateControlTextState = (newProps: Partial<ControlTextState>, isPreventWatcher = false) => {
      isControlTextStateWatcherActive = true;
      Object.assign(controlTextState, newProps);
      if (isPreventWatcher) {
        isControlTextStateWatcherActive = false;
      }
    };
    /** This function is going to reset the state of board */
    const clearTheBoard = () => {
      /** Reset audio background state */
      audioLocalTime.value = 0;
    };
    const deactiveObject = () => {
      canvas?.discardActiveObject();
      canvas?.renderAll();
    };

    const clearObject = () => {
      canvas.remove(...canvas.getObjects().filter((item: any) => item.id !== "lesson-img"));
    };

    const pauseMedia = () => {
      videoRef.value?.pause();
      audioRef.value?.pause();
    };

    watch(imgCoords, (currentValue) => {
      if (currentValue) {
        const viewport = canvas.viewportTransform;
        viewport[4] = currentValue.x;
        viewport[5] = currentValue.y;
        canvas?.setViewportTransform(viewport);
      }
      canvas?.renderAll();
    });

    watch(
      () => props.image?.url,
      () => {
        video.value?.load();
        audio.value?.load();
        pdfRef.value && scrollPdfToPercentage(0, false);
      },
    );

    nextColor.value = strokeColor.value;

    const zoomIn = async () => {
      if (zoomRatio.value > MAX_ZOOM_RATIO) {
        return;
      }
      if (canvas.getZoom() !== zoomRatio.value) {
        zoomRatio.value = canvas.getZoom();
      }
      zoomRatio.value = zoomRatio.value + ZOOM_STEP > MAX_ZOOM_RATIO ? MAX_ZOOM_RATIO : zoomRatio.value + ZOOM_STEP;
      canvas.zoomToPoint(point, zoomRatio.value);
      canvas.forEachObject(function (o: any) {
        o.setCoords();
      });
      canvas.calcOffset();
      zoomPercentage.value = Math.round(zoomRatio.value * DEFAULT_ZOOM_PERCENT);

      const viewportTransform = canvas.viewportTransform;
      await dispatch("teacherRoom/setZoomSlide", {
        ratio: zoomRatio.value,
        position: {
          x: Math.floor(viewportTransform[4]),
          y: Math.floor(viewportTransform[5]),
        },
      });
      await dispatch("lesson/setZoomRatio", zoomRatio.value, {
        root: true,
      });
    };

    const zoomOut = async () => {
      if (canvas.getZoom() > MIN_ZOOM_RATIO) {
        if (canvas.getZoom() !== zoomRatio.value) {
          zoomRatio.value = canvas.getZoom();
        }
        zoomRatio.value -= 0.1;
        if (zoomRatio.value < MIN_ZOOM_RATIO) {
          zoomRatio.value = MIN_ZOOM_RATIO;
        }
        zoomPercentage.value = Math.round(zoomRatio.value * DEFAULT_ZOOM_PERCENT);
        canvas.zoomToPoint(point, zoomRatio.value);
        const viewportTransform = canvas.viewportTransform;
        await dispatch("teacherRoom/setZoomSlide", {
          ratio: zoomRatio.value,
          position: {
            x: Math.floor(viewportTransform[4]),
            y: Math.floor(viewportTransform[5]),
          },
        });
        await dispatch("lesson/setZoomRatio", zoomRatio.value, {
          root: true,
        });
      }
    };

    const resetCanvasPanning = async () => {
      const viewport = canvas.viewportTransform;
      viewport[4] = 0;
      viewport[5] = 0;
      canvas.setViewportTransform(viewport);
      await dispatch("teacherRoom/setMoveZoomedSlide", { x: 0, y: 0 });
      await dispatch(
        "lesson/setImgCoords",
        { x: 0, y: 0 },
        {
          root: true,
        },
      );
    };

    const addSnapshotCanvasWithTargetsToPreview = () => {
      if (!group) {
        return;
      }
      group.clone(
        (cloned: any) => {
          cloned.visible = true;
          cloned.getObjects().forEach((obj: any) => {
            if (obj.id !== "lesson-img") {
              obj.fill = obj[CustomPropertiesForTarget.RealFill] ?? "transparent";
              obj.opacity = obj[CustomPropertiesForTarget.RealOpacity] ?? 1;
              obj.stroke = obj[CustomPropertiesForTarget.RealStroke] ?? "black";
            }
          });

          const objectToString = `{"objects":[${JSON.stringify(cloned)}], "background":"transparent"}`;
          dispatch("lesson/setLessonPreviewObjects", objectToString, { root: true });
        },
        ["id", CustomPropertiesForTarget.RealFill, CustomPropertiesForTarget.RealOpacity, CustomPropertiesForTarget.RealStroke],
      );
    };

    const mediaTypeId = computed(() => {
      const newId = props.id;
      let result = undefined;
      const listMedia = currentExposure.value?.alternateMediaBlockItems.flat();
      if (listMedia) {
        const target = listMedia.find((item) => {
          return newId === item.id;
        });
        if (target) {
          result = target.mediaTypeId;
        }
      }
      return result;
    });

    const isValidUrl = computed(() => {
      return currentExposureItemMedia.value?.image.url !== "default";
    });

    const showHidePreviewModal = async (isShowPreview = true) => {
      await dispatch("lesson/setShowPreviewCanvas", isShowPreview, {
        root: true,
      });
    };

    watch(sessionZoomRatio, async (value) => {
      if (value == MIN_ZOOM_RATIO) {
        zoomRatio.value = MIN_ZOOM_RATIO;
        zoomPercentage.value = DEFAULT_ZOOM_PERCENT;
        canvas.zoomToPoint(point, MIN_ZOOM_RATIO);
        if (group) {
          await resetCanvasPanning();
        }
      }
      if (sessionZoomRatio.value && canvas.getZoom() !== sessionZoomRatio.value) {
        zoomRatio.value = sessionZoomRatio.value;
        if (canvas && point) {
          canvas.zoomToPoint(point, sessionZoomRatio.value);
        }
      }
      zoomPercentage.value = Math.floor(value.toFixed(2) * DEFAULT_ZOOM_PERCENT);
    });

    watch(isShowPreviewCanvas, (currentValue) => {
      disablePreviewBtn.value = currentValue;
    });
    const isCropImage = computed<boolean>(() => !!props.image?.metaData && props.image.metaData.width > 0 && props.image.metaData.height > 0);
    watch(currentExposureItemMedia, async (currentItem, prevItem) => {
      await nextTick();
      if (currentItem) {
        zoomRatio.value = 1;
        zoomPercentage.value = 100;
        canvas.zoomToPoint(point, MIN_ZOOM_RATIO);
        const viewport = canvas.viewportTransform;
        viewport[4] = 0;
        viewport[5] = 0;
        canvas.setViewportTransform(viewport);
        await dispatch("lesson/setImgCoords", undefined, {
          root: true,
        });
      } else {
        canvas.remove(group);
      }
      if (currentItem && prevItem) {
        if (currentItem.id !== prevItem.id) {
          canvas.zoomToPoint(point, MIN_ZOOM_RATIO);
          await showHidePreviewModal(false);
          disablePreviewBtn.value = false;
        }
      }
      if (mediaTypeId.value !== undefined && currentItem?.image?.url === "default") {
        await dispatch("lesson/getAlternateMediaUrl", {
          token: loginInfo.access_token,
          id: currentItem.id,
        });
      }
      if (currentItem && prevItem && currentItem.image.url === prevItem.image.url) {
        await onLPSlideLoaded();
      }
      clearTheBoard();
      resetReloadKey();
    });
    watch(teacherDisconnected, (currentValue) => {
      if (currentValue) {
        firstTimeLoadStrokes.value = false;
        firstTimeLoadShapes.value = false;
        return;
      }
    });
    watch(studentDisconnected, (currentValue) => {
      if (currentValue) {
        firstTimeLoadStrokes.value = false;
        firstTimeLoadShapes.value = false;
        return;
      }
    });
    const targetsNum = computed(() => {
      const uniqueAnnotations: any[] = [];
      props.image?.metaData?.annotations?.forEach((metaDataObj: any) => {
        if (
          !uniqueAnnotations.some(
            (_obj: any) =>
              metaDataObj.color === _obj.color &&
              metaDataObj.height === _obj.height &&
              metaDataObj.width === _obj.width &&
              metaDataObj.opacity === _obj.opacity &&
              metaDataObj.rotate === _obj.rotate &&
              metaDataObj.type === _obj.type &&
              metaDataObj.x === _obj.x &&
              metaDataObj.y === _obj.y,
          )
        ) {
          uniqueAnnotations.push(metaDataObj);
        }
      });
      return uniqueAnnotations.length;
    });
    const hasTargets = computed(() => {
      return !!props.image?.metaData?.annotations && targetsNum.value;
    });
    const targetTextLocalize = computed(() => fmtMsg(TeacherClass.TargetText));
    const targetsTextLocalize = computed(() => fmtMsg(TeacherClass.TargetsText));

    const targetText = computed(() => {
      if (props.image?.metaData?.annotations?.length == 1) {
        return targetTextLocalize.value;
      } else {
        return targetsTextLocalize.value;
      }
    });
    const disablePreviewBtn: Ref<boolean> = ref(false);
    const setAnnotationMode = async (mode: Mode) => {
      modeAnnotation.value = mode;
      await dispatch("teacherRoom/setMode", {
        mode,
      });
    };
    const setCursorMode = async () => {
      setAnnotationMode(Mode.Cursor);
    };
    const setDrawMode = async () => {
      setAnnotationMode(Mode.Draw);
    };
    const handleMouseOver = () => {
      isMouseMoveOut.value = false;
    };
    const handleMouseOut = () => {
      isMouseMoveOut.value = true;
    };
    watch(isMouseMoveOut, async () => {
      if (toolSelected.value !== Tools.Cursor) {
        return;
      }
      if (isMouseMoveOut.value) {
        setAnnotationMode(Mode.Draw);
      } else {
        setAnnotationMode(Mode.Cursor);
      }
    });
    const processCanvasWhiteboard = async (shouldResetClickedTool = true) => {
      if (!canvas) return;
      if (isWbVisible.value) {
        // canvas.remove(...canvas.getObjects().filter((obj: any) => obj.id === "annotation-lesson"));
        canvas
          .getObjects()
          .filter((obj: any) => obj.id === "annotation-lesson")
          .forEach((obj: any) => {
            obj.set("visible", false);
          });
        canvas.setBackgroundColor("white", canvas.renderAll.bind(canvas));
        if (shouldResetClickedTool) {
          await clickedTool(Tools.Pen);
        }
      } else {
        canvas.remove(...canvas.getObjects("path"));
        canvas.remove(...canvas.getObjects("textbox"));
        canvas
          .getObjects()
          .filter((obj: any) => obj.id === "annotation-lesson")
          .forEach((obj: any) => {
            obj.set("visible", true);
          });
        canvas.setBackgroundColor("transparent", canvas.renderAll.bind(canvas));
        // await clickedTool(Tools.Cursor);
        canvas.renderAll();
      }
    };
    watch(isWbVisible, async () => {
      await processCanvasWhiteboard(false);
      isWbVisible.value;
      if (group) {
        group.visible = !isWbVisible.value;
      }
      canvas.renderAll();
    });
    watch(
      () => currentExposureItemMedia.value?.teacherUseOnly && !isWbVisible.value,
      (val) => {
        commit("teacherRoom/setIsTeacherUseOnly", !!val);
      },
    );
    const handlePointsSkipped = (_: any) => {
      if (isDrawing.value) {
        const pointer = canvas.getPointer();
        if (!pointer) {
          return;
        }
        pointsSkipped.value.push({
          x: Math.floor(pointer.x),
          y: Math.floor(pointer.y),
        });
      }
    };

    const handleSendLaserPath = async (data: any) => {
      if (!data) return;
      const payload: SendRealtimePenMessage = {
        data: JSON.stringify(data),
        isOneOne: !!studentOneAndOneId.value,
      };
      await dispatch("teacherRoom/setLaserPath", payload);
    };

    const handleSendPencilPath = async (data: any) => {
      if (!data) return;
      const payload: SendRealtimePenMessage = {
        data: JSON.stringify(data),
        isOneOne: !!studentOneAndOneId.value,
      };
      await dispatch("teacherRoom/setPencilPath", payload);
    };

    const sendCursorPosition = throttle(
      async (e: any, isDone = false) => {
        if (modeAnnotation.value === Mode.Cursor) {
          await dispatch("teacherRoom/setPointer", {
            x: Math.floor(e.pointer?.x ?? prevPoint.value?.x ?? 0),
            y: Math.floor(e.pointer?.y ?? prevPoint.value?.y ?? 0),
            isOneOne: !!studentOneAndOneId.value,
          });
          return;
        }
      },
      100,
      { leading: true, trailing: true },
    );

    const sendZoomingState = debounce(async (viewportTransform: any, zoomRatio: any) => {
      await dispatch("teacherRoom/setZoomSlide", {
        ratio: zoomRatio,
        position: {
          x: Math.floor(viewportTransform[4]),
          y: Math.floor(viewportTransform[5]),
        },
      });
    }, 300);

    const cursorPosition = async (e: any, isDone = false) => {
      if (modeAnnotation.value === Mode.Cursor) {
        await sendCursorPosition(e, isDone);
        return;
      }
      if ((toolSelected.value === Tools.Laser || toolSelected.value === Tools.Pen) && isDrawing.value) {
        const pointer = canvas.getPointer(e);
        const _point = {
          x: Math.floor(pointer?.x ?? prevPoint.value?.x ?? 0),
          y: Math.floor(pointer?.y ?? prevPoint.value?.y ?? 0),
        };
        if (!prevPoint.value || diff.value === DIFF_BETWEEN_POINT || !diff.value || isDone) {
          prevPoint.value = _point;
        }
        if (diff.value === DIFF_BETWEEN_POINT || !diff.value || isDone) {
          if (isMouseOut.value) {
            lineId.value = generateRealtimeLineId();
            if (toolSelected.value === Tools.Laser) {
              await handleSendLaserPath({
                data: {
                  id: lineId.value,
                  points: _point,
                  strokeColor: strokeColor.value,
                  strokeWidth: strokeWidth.value,
                  pointsSkipped: pointsSkipped.value,
                },
                isDone,
              });
            } else {
              await handleSendPencilPath({
                id: lineId.value,
                points: _point,
                strokeColor: strokeColor.value,
                strokeWidth: strokeWidth.value,
                lineIdRelated: prevLineId.value,
                isDone,
                pointsSkipped: pointsSkipped.value,
                objectId: lineObjectId.value,
              });
            }
            isMouseOver.value = false;
            isMouseOut.value = false;
            prevPoint.value = undefined;
          } else {
            if (toolSelected.value === Tools.Laser) {
              await handleSendLaserPath({
                data: {
                  id: lineId.value,
                  points: _point,
                  strokeColor: strokeColor.value,
                  strokeWidth: strokeWidth.value,
                  pointsSkipped: pointsSkipped.value,
                },
                isDone,
              });
            } else {
              await handleSendPencilPath({
                id: lineId.value,
                points: _point,
                strokeColor: strokeColor.value,
                strokeWidth: strokeWidth.value,
                lineIdRelated: "",
                isDone,
                pointsSkipped: pointsSkipped.value,
                objectId: lineObjectId.value,
              });
            }
          }
          return;
        }
      }
    };
    const getLastLineObject = () => {
      const teacherStrokes = canvas.getObjects(FbObjects.Path);
      return teacherStrokes[teacherStrokes.length - 1];
    };
    const sendLastLine = async () => {
      const lastLine = getLastLineObject();
      if (toolSelected.value === Tools.Pen && lastLine) {
        await dispatch("teacherRoom/sendLastLineAsync", {
          drawing: lastLine,
        });
      }
    };
    const laserDraw = () => {
      const laserPath = canvas.getObjects("path").pop();
      laserPath.isLaserPath = true;
      laserPath.animate("opacity", "0", {
        duration: 1000,
        easing: fabric.util.ease.easeInOutExpo,
        onChange: canvas.renderAll.bind(canvas),
        onComplete: () => {
          canvas.remove(laserPath);
        },
      });
    };
    const listenBeforeTransform = () => {
      canvas.on("before:transform", (e: any) => {
        if (e.transform.target.id === "lesson-img") {
          const sel = new fabric.ActiveSelection(
            canvas.getObjects().filter((item: any) => item.id !== "lesson-img" && item.id !== "annotation-lesson"),
            {
              canvas: canvas,
            },
          );
          canvas.setActiveObject(sel);
          canvas.discardActiveObject();
          canvas.renderAll();
        }
      });
    };
    const listenToMouseUp = () => {
      canvas.on(FbEvents.MouseUp, async (event: any) => {
        if (toolSelected.value === Tools.Pen) {
          cursorPosition(event.e, true);
          lineId.value = generateRealtimeLineId();
          isDrawing.value = false;
          prevPoint.value = undefined;
          prevLineId.value = "";
          canvas.renderAll();
          await sendLastLine();
        }
        if (toolSelected.value === Tools.Laser) {
          cursorPosition(event.e, true);
          isDrawing.value = false;
          prevPoint.value = undefined;
          canvas.renderAll();
          laserDraw();
        }
        if (
          toolSelected.value === Tools.Circle ||
          toolSelected.value === Tools.Square ||
          toolSelected.value === Tools.Cursor ||
          toolSelected.value === Tools.TextBox
        ) {
          if (canvas.getActiveObject()?.type !== FbObjects.Textbox) {
            await sendRequestUpdateCanvasObjectsConsideredShapes(canvas);
          }
        }
      });
    };
    const listenToMouseOut = () => {
      canvas.on(FbEvents.MouseOut, async (_: any) => {
        if (isDrawing.value) {
          isMouseOut.value = true;
        }
      });
    };
    const listenToMouseOver = () => {
      canvas.on(FbEvents.MouseOver, async (_: any) => {
        if (isDrawing.value) {
          isMouseOver.value = true;
        }
      });
    };
    const listenCreatedPath = () => {
      canvas.on("path:created", (obj: any) => {
        obj.path.id = teacher.value?.id;
        obj.path.isOneToOne = studentOneAndOneId.value || null;
      });
    };
    const listenSelfTeacher = () => {
      getAllObjectsExceptBackgroundAndTargets(canvas).forEach((item: any) => {
        item.selectable = true;
      });
    };
    const preventProcessAnnotation = ref(false);
    const panning = ref(false);
    const listenMouseEvent = () => {
      //handle mouse:move
      canvas.on("mouse:move", (event: any) => {
        diff.value += 1;
        handlePointsSkipped(event);
        cursorPosition(event);
        if (diff.value === DIFF_BETWEEN_POINT) {
          diff.value = 0;
          pointsSkipped.value = [];
        }
        switch (toolSelected.value) {
          //handle for TextBox
          case Tools.TextBox: {
            if (!event.target) {
              canvas.setCursor(Cursor.Text);
              canvas.renderAll();
            }
            break;
          }
          default:
            break;
        }
        if (panning.value && event && event.e) {
          const delta = new fabric.Point(event.e.movementX, event.e.movementY);
          canvas.relativePan(delta);
        }
      });
      //handle mouse:down
      const handleMouseDown = async (event: any) => {
        switch (toolSelected.value) {
          //handle for TextBox
          case Tools.TextBox: {
            if (event.target && !isGroup(event.target)) {
              isEditing.value = true;
              preventProcessAnnotation.value = true;
              break;
            }
            if (!isEditing.value) {
              await createTextBox(
                canvas,
                {
                  top: event.absolutePointer.y,
                  left: event.absolutePointer.x,
                },
                controlTextState,
              );
            } else {
              isEditing.value = false;
            }
            preventProcessAnnotation.value = true;
            break;
          }
          case Tools.Laser: {
            isDrawing.value = true;
            preventProcessAnnotation.value = true;
            break;
          }
          case Tools.Pen: {
            prevLineId.value = lineId.value;
            isDrawing.value = true;
            preventProcessAnnotation.value = true;
            break;
          }
          default: {
            const activeFabricObject = canvas.getActiveObject();
            if (!activeFabricObject) {
              panning.value = true;
            }
            break;
          }
        }
      };
      const handleMouseUp = () => {
        if (panning.value) {
          panning.value = false;
          const viewport = canvas.viewportTransform;
          const coords = { x: Math.floor(viewport[4]), y: Math.floor(viewport[5]) };
          dispatch("teacherRoom/setMoveZoomedSlide", coords);
          dispatch("lesson/setImgCoords", coords, { root: true });
        }
        if (preventProcessAnnotation.value) {
          preventProcessAnnotation.value = false;
          return;
        }
      };
      canvas.on(FbEvents.MouseDown, async (event: any) => {
        if (toolSelected.value === Tools.Pen || toolSelected.value === Tools.Laser) {
          lineObjectId.value = createGuid();
        }
        await handleMouseDown(event);
      });
      canvas.on(FbEvents.MouseUp, handleMouseUp);
      // handle mouse wheel
      canvas.on(FbEvents.MouseWheel, async (opt: any) => {
        const delta = opt.e.deltaY;
        let zoom = canvas.getZoom();
        zoom *= 0.999 ** delta;
        if (zoom > MAX_ZOOM_RATIO) zoom = MAX_ZOOM_RATIO;
        if (zoom < MIN_ZOOM_RATIO) zoom = MIN_ZOOM_RATIO;
        canvas.zoomToPoint(point, zoom);
        opt.e.preventDefault();
        opt.e.stopPropagation();
        zoomRatio.value = zoom;
        zoomPercentage.value = Math.round(zoomRatio.value * DEFAULT_ZOOM_PERCENT);
        await dispatch("lesson/setZoomRatio", zoomRatio);
        await sendZoomingState(canvas.viewportTransform, zoom);
      });
    };
    watch(toolSelected, (tool, prevTool) => {
      if (prevTool !== tool && tool !== Tools.TextBox) {
        canvas.discardActiveObject();
        canvas.renderAll();
        isEditing.value = false;
      }
    });

    // LISTENING TO CANVAS EVENTS
    const listenToCanvasEvents = () => {
      listenBeforeTransform();
      listenToMouseUp();
      listenToMouseOut();
      listenToMouseOver();
      listenCreatedPath();
      listenSelfTeacher();
      onObjectModified(canvas, {
        handleEditObjectFromCanvas,
      });
      onTextBoxEdited(canvas);
      onObjectSelected(canvas);
      listenMouseEvent();
      onObjectCreated(canvas);
      onObjectRemoved(canvas);
      onSelectionChanged(canvas, {
        openTextControlPassively: async (target: any) => {
          if (target.type === FbObjects.Textbox) {
            const getLastCharacterStyles = (target: any) => {
              const lastIndex = target.text.length - 1;
              const lastCharStyles = target.getSelectionStyles(lastIndex, lastIndex + 1)[0];
              const fontSize = lastCharStyles.fontSize || target.fontSize;
              const fill = lastCharStyles.fill || target.fill;
              const fontFamily = lastCharStyles.fontFamily || target.fontFamily;
              return { fontSize, fill, fontFamily };
            };
            const { fontSize, fill, fontFamily } = getLastCharacterStyles(target);
            updateNextStyles({ fontSize, fill, fontFamily });
            updateControlTextState({ fontSize, fill, fontFamily }, true);
            toolSelected.value = Tools.TextBox;
          }
        },
      });
    };
    const boardSetup = async () => {
      canvas = new fabric.Canvas("canvasDesignate");
      canvas.preserveObjectStacking = true;
      canvas.setWidth(DefaultCanvasDimension.width);
      canvas.setHeight(DefaultCanvasDimension.height);
      initCanvas(canvas);
      point = new fabric.Point(DefaultCanvasDimension.width / 2, DefaultCanvasDimension.height / 2);
      canvas.selectionFullyContained = false;
      try {
        await FontLoader.load();
        await processCanvasWhiteboard();
      } catch (error) {
        Logger.log(error);
      }
      listenToCanvasEvents();
    };
    const disableInteractivityForNonTeacherElements = () => {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.id !== teacher.value?.id && !obj.objectId && !isGroup(obj)) {
          obj.selectable = false;
          obj.hasControls = false;
          obj.hasBorders = false;
          obj.hoverCursor = "cursor";
          obj.perPixelTargetFind = true;
        }
      });
    };
    const clickedTool = async (tool: Tools) => {
      if (pointsSkipped.value.length) {
        pointsSkipped.value = [];
      }
      if (tool === Tools.StrokeColor) {
        disableInteractivityForNonTeacherElements();
        return;
      }
      if (tool === Tools.Stroke) {
        disableInteractivityForNonTeacherElements();
        return;
      }
      canvas.selection = false;
      canvas.isDrawingMode = tool === Tools.Pen;
      if (toolSelected.value !== tool && tool !== Tools.Delete) {
        toolSelected.value = tool;
      } else {
        if (toolSelected.value === Tools.TextBox) return;
      }
      switch (tool) {
        case Tools.TextBox:
          await setDrawMode();
          return;
        case Tools.Cursor:
          toolSelected.value = Tools.Cursor;
          canvas.isDrawingMode = false;
          await setCursorMode();
          disableInteractivityForNonTeacherElements();
          return;
        case Tools.Pen:
          toolSelected.value = Tools.Pen;
          await setDrawMode();
          canvas.freeDrawingBrush.color = strokeColor.value;
          canvas.freeDrawingBrush.width = strokeWidth.value;
          disableInteractivityForNonTeacherElements();
          return;
        case Tools.Laser:
          toolSelected.value = Tools.Laser;
          canvas.isDrawingMode = true;
          await setDrawMode();
          return;
        case Tools.Delete: {
          const lastObject = findLatestCreatedItem(getAllUndoableObjects(canvas));
          if (lastObject) await handleRemoveObjectFromCanvas(lastObject);
          toolSelected.value = Tools.Pen;
          canvas.isDrawingMode = true;
          await setDrawMode();
          return;
        }
        case Tools.Clear: {
          toolSelected.value = Tools.Clear;
          canvas.remove(...getAllObjectsExceptBackgroundAndTargets(canvas));
          await requestClearBoard();
          toolSelected.value = Tools.Pen;
          canvas.isDrawingMode = true;
          await setDrawMode();
          return;
        }
        case Tools.Circle:
          toolSelected.value = Tools.Circle;
          await setDrawMode();
          await addCircle(canvas, strokeColor, strokeWidth, studentOneAndOneId);
          disableInteractivityForNonTeacherElements();
          return;
        case Tools.Square:
          toolSelected.value = Tools.Square;
          await setDrawMode();
          await addSquare(canvas, strokeColor, strokeWidth, studentOneAndOneId);
          disableInteractivityForNonTeacherElements();
          return;
        default:
          return;
      }
    };
    const updateColorValue = async (fill: string) => {
      await updateSelectedObjectStyles(canvas, { fill });
      strokeColor.value = fill;
      canvas.freeDrawingBrush.color = fill;
    };
    const updateStrokeWidth = (value: number) => {
      strokeWidth.value = value;
      canvas.freeDrawingBrush.width = value;
    };
    const showWhiteboard = async () => {
      pauseMedia();
      await dispatch("teacherRoom/setWhiteboard", {
        isShowWhiteBoard: true,
      });
      clearObject();
      await requestClearBoard();
      canvas.freeDrawingBrush.color = strokeColor.value;
      canvas.freeDrawingBrush.width = strokeWidth.value;
    };
    const hideWhiteboard = async () => {
      await dispatch("teacherRoom/setWhiteboard", {
        isShowWhiteBoard: false,
      });
      clearObject();
      await requestClearBoard();
    };

    const imgRef = ref(null);
    const imgCropRef = ref<any>(null);
    const initializeSlideOnCanvas = (propImage: any, canvas: any, point: any, firstLoad: any, imgEl: any) => {
      const isRotatedSlide = isRotatedLPSlide(propImage.image.metaData);
      const imgWidth = isRotatedSlide ? getters["annotation/imgHeight"] : getters["annotation/imgWidth"];
      const imgHeight = isRotatedSlide ? getters["annotation/imgWidth"] : getters["annotation/imgHeight"];
      const canvasGroup = canvas.getObjects().find((item: any) => item?.id === ConnectObjectTypes.LessonBackground);
      const imageRatio = Math.max(imgWidth / DefaultCanvasDimension.width, imgHeight / DefaultCanvasDimension.height);
      const renderWidth = imgWidth / imageRatio;
      const renderHeight = imgHeight / imageRatio;
      if (!propImage.image?.url) {
        return;
      }
      if (canvasGroup) {
        canvas.remove(canvasGroup);
      }
      const clipPath = new fabric.Rect({
        width: renderWidth,
        height: renderHeight,
        left: (DefaultCanvasDimension.width - renderWidth) / 2,
        top: 0,
        absolutePositioned: true,
      });
      const angle = propImage.image.metaData
        ? propImage.image.metaData.width > 0 && propImage.image.metaData.height > 0
          ? 0
          : propImage.image.metaData.rotate
        : 0;
      const Image = new fabric.Image(imgEl, {
        id: ConnectObjectTypes.LessonBackground,
        originX: FbObjectOrigins.Center,
        originY: FbObjectOrigins.Center,
        angle,
        selectable: false,
        hasBorders: false,
        hasControls: false,
      });
      Image.scaleToWidth(renderWidth);
      Image.scaleToHeight(renderHeight);
      const left = DefaultCanvasDimension.width / 2;
      const top = renderHeight / 2;
      const Group = new fabric.Group([Image], {
        id: ConnectObjectTypes.LessonBackground,
        clipPath,
        originX: FbObjectOrigins.Center,
        originY: FbObjectOrigins.Center,
        left,
        top,
        selectable: false,
        hoverCursor: "pointer",
        scaleX: propImage.image?.metaData?.scaleX ?? 1,
        scaleY: propImage.image?.metaData?.scaleY ?? 1,
        hasBorders: false,
        hasControls: false,
        layout: "clip-path",
        interactive: true,
        subTargetCheck: true,
        visible: !isWbVisible.value,
      });
      if (!isImgProcessing.value) {
        if (top !== Group.top) {
          Group.realTop = Group.top;
        }
        if (left !== Group.left) {
          Group.realLeft = Group.left;
        }
        canvas.add(Group);
        canvas.sendToBack(Group);
        if (zoomRatio.value > MIN_ZOOM_RATIO) {
          canvas.zoomToPoint(point, zoomRatio.value);
        }
        if (imgCoords.value) {
          const viewport = canvas.viewportTransform;
          if (imgCoords.value.x) {
            viewport[4] = imgCoords.value.x;
          }
          if (imgCoords.value.y) {
            viewport[5] = imgCoords.value.y;
          }
          canvas.setViewportTransform(viewport);
        }
      }
      return Group;
    };
    const onLPSlideLoaded = async (e?: Event) => {
      if (!canvas || !currentExposureItemMedia.value) return;
      let img: null | HTMLImageElement;
      if (e) {
        img = e?.target as HTMLImageElement;
      } else {
        if (isCropImage.value) {
          img = imgCropRef.value?.imageRef;
        } else {
          img = imgRef.value;
        }
      }
      if (!img) return;
      if (img.naturalWidth && img.naturalHeight) {
        await dispatch("annotation/setImgDimension", {
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      } else {
        await dispatch("annotation/setImgDimension", {
          width: undefined,
          height: undefined,
        });
      }
      img.crossOrigin = "anonymous";
      group = await initializeSlideOnCanvas(currentExposureItemMedia.value, canvas, point, !firstLoadImage.value, img);
      if (currentExposureItemMedia.value.image.metaData?.annotations?.length && img.naturalWidth) {
        initializeTargets(currentExposureItemMedia.value.image.metaData);
        addSnapshotCanvasWithTargetsToPreview();
      }
      if (!firstLoadImage.value) {
        firstLoadImage.value = true;
      }
    };
    const resetBoard = async () => {
      canvas.isDrawingMode = true;
      await setDrawMode();
      await requestClearBoard();
    };
    const renderShapesOnCanvas = () => {
      removeShapeObjectsFromCanvas();
      if (shapes.value && shapes.value.length > 0) {
        shapes.value.forEach((item: any) => {
          brushstrokesRender(canvas, item, null, "self-shapes");
        });
        listenSelfTeacher();
      }
      if (studentOneAndOneId.value && shapesOne.value && shapesOne.value.length > 0) {
        shapesOne.value.forEach((item: any) => {
          brushstrokesRender(canvas, item, null, "self-shapes");
        });
        listenSelfTeacher();
      }
    };
    const renderLinesOnCanvas = () => {
      if (lines.value) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => isPath(obj)));
        lines.value.forEach((s: any) => {
          const path = new fabric.Path.fromObject(JSON.parse(s), (item: any) => {
            item.id = teacher.value?.id;
            item.isOneToOne = null;
            canvas.add(item);
            canvas.renderAll();
          });
        });
        disableInteractivityForNonTeacherElements();
      }
    };
    watch(lines, async () => {
      // await nextTick();
      if (!firstTimeLoadStrokes.value && lines.value) {
        renderLinesOnCanvas();
        firstTimeLoadStrokes.value = !isSocketAttemptedReconnecting.value;
      } else if (lines.value?.length === 0) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => isPath(obj)));
      }
    });
    const renderOneTeacherStrokes = () => {
      if (linesOne.value && linesOne.value.length > 0) {
        canvas.remove(...canvas.getObjects("path"));
        linesOne.value.forEach((s: any) => {
          const path = new fabric.Path.fromObject(JSON.parse(s), (item: any) => {
            item.isOneToOne = studentOneAndOneId.value;
            item.tag = "teacher-strokes-one";
            canvas.add(item);
          });
        });
        disableInteractivityForNonTeacherElements();
      } else {
        canvas.remove(...canvas.getObjects("path").filter((obj: any) => obj.tag === "teacher-strokes-one"));
      }
    };

    watch(linesOne, () => {
      renderOneTeacherStrokes();
    });

    watch(shapes, async (val) => {
      if (!firstTimeLoadShapes.value && val) {
        renderShapesOnCanvas();
        firstTimeLoadShapes.value = !isSocketAttemptedReconnecting.value;
      } else if (val?.length === 0) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === FbObjects.Circle || obj.type === FbObjects.Rect));
      }
    });
    const teacherSharingShapes = (dataShapes: Array<UserShape>, studentOneId: string | null) => {
      if (dataShapes && dataShapes.length) {
        const teacherObjects = canvas.getObjects().filter((object: any) => {
          return object.type === FbObjects.Circle || object.type === FbObjects.Rect;
        });

        canvas.remove(...teacherObjects);

        dataShapes.forEach((item) => {
          brushstrokesRender(canvas, item, studentOneId, "teacher-shapes");
        });
      } else {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.tag === "teacher-shapes"));
      }
    };
    watch(
      shapes,
      (val) => {
        if (isHelper.value) {
          teacherSharingShapes(val, null);
        }
      },
      { deep: true },
    );

    watch(shapesOne, async () => {
      if (!firstTimeLoadOneToOneShapes.value && studentOneAndOneId.value && shapesOne.value) {
        renderShapesOnCanvas();
        firstTimeLoadOneToOneShapes.value = true;
      } else if (studentOneAndOneId.value && shapesOne.value && shapesOne.value.length === 0) {
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.type === FbObjects.Circle || obj.type === FbObjects.Rect));
      }
    });
    watch(studentOneAndOneId, async () => {
      if (!canvas) return;
      const activeFabricObject = canvas.getActiveObject();
      if (activeFabricObject?.type === "textbox" && activeFabricObject.isEditing) {
        activeFabricObject.exitEditing();
        canvas.discardActiveObject();
        canvas.renderAll();
      }
      if (!studentOneAndOneId.value) {
        // remove all objects in mode 1-1
        canvas.remove(...canvas.getObjects().filter((obj: any) => obj.isOneToOne !== null && obj.id !== "lesson-img"));
        // render objects again before into mode 1-1
        // remove and render objects again of teacher, set object can move
        setTimeout(() => {
          renderLinesOnCanvas();
          renderShapesOnCanvas();
        }, 800);
        await processCanvasWhiteboard(false);
        scrollPdfToPercentage(prevPdfScrollProgress.value);
        listenSelfTeacher();
      } else {
        prevZoomRatio.value = canvas.getZoom();
        prevCoords.value = {
          x: group?.left,
          y: group?.top,
        };
        prevPdfScrollProgress.value = pdfScrollProgress.value;
      }
    });
    watch(texts, async (val) => {
      removeTextBoxObjectsFromCanvas();
      displayFabricItems(canvas, val);
    });
    watch(lastDeletedFabricObjectId, async (mustDelId) => {
      if (mustDelId) canvas.remove(...canvas.getObjects().filter((obj: any) => obj.objectId === mustDelId));
      commit("annotation/setLastFabricIdDeleted", null, { root: true });
    });
    const getFabricItemById = (id: string) => {
      const currentObjects = canvas.getObjects();
      return currentObjects.find((obj: any) => obj.objectId === id);
    };
    watch(lastUpdatedFabricObject, async (val: LastFabricUpdated) => {
      if (!val) return;
      const { type, data } = val;
      switch (type) {
        case FabricUpdateType.CREATE: {
          displayCreatedItem(canvas, data);
          await dispatch("annotation/setFabricObjects", data);
          break;
        }
        case FabricUpdateType.MODIFY: {
          const existingObject = getFabricItemById(data.fabricId);
          if (!existingObject) {
            displayCreatedItem(canvas, data);
            await dispatch("annotation/setFabricObjects", data);
            break;
          }
          await dispatch("annotation/setFabricObjects", data);
          displayModifiedItem(canvas, data, existingObject);
          break;
        }
        default:
          break;
      }
    });

    watch(
      video,
      async (currentVideo) => {
        videoRef.value = currentVideo;
        if (!video.value) return;
        video.value.onloadstart = setSinkId;
        if (!isHelper.value) {
          video.value.onplay = async () => {
            video.value?.setAttribute("dirty", "true");
            const payload = {
              isPlay: true,
              duration: video.value?.currentTime ? parseInt(video.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
          video.value.onpause = async () => {
            video.value?.setAttribute("dirty", "true");
            const payload = {
              isPlay: false,
              duration: video.value?.currentTime ? parseInt(video.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
          video.value.onseeked = async () => {
            video.value?.setAttribute("dirty", "true");
            const payload = {
              isPlay: !video.value?.paused,
              duration: video.value?.currentTime ? parseInt(video.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
        }
      },
      { deep: true },
    );
    watch(
      audio,
      async (currentAudio) => {
        audioRef.value = currentAudio;
        if (!audio.value) return;
        audio.value.onloadstart = setSinkId;
        if (!isHelper.value) {
          audio.value.onplay = async () => {
            const payload = {
              isPlay: true,
              duration: audio.value?.currentTime ? parseInt(audio.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
          audio.value.onpause = async () => {
            const payload = {
              isPlay: false,
              duration: audio.value?.currentTime ? parseInt(audio.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
          audio.value.onseeked = async () => {
            const payload = {
              isPlay: !audio.value?.paused,
              duration: audio.value?.currentTime ? parseInt(audio.value?.currentTime.toFixed(0)) : 0,
            };
            await debounceSendApiUpdateMediaState(payload);
          };
        }
      },
      { deep: true },
    );

    const activeElement = useActiveElement();
    watch(activeElement, (el) => {
      const activeFabricObject = canvas.getActiveObject();
      if (activeFabricObject?.type === "textbox" && activeFabricObject.isEditing) {
        activeFabricObject.hiddenTextarea.focus();
        activeFabricObject.enterEditing();
      }
    });
    onMounted(async () => {
      const callbacks = {
        handlePressRemoveIcon,
      };
      initConf(callbacks);
      await boardSetup();
      await resetBoard();
      canvas.freeDrawingBrush.color = Colors.Black;
      canvas.freeDrawingBrush.width = strokeWidth.value;
    });
    onUnmounted(() => {
      canvas.dispose();
    });

    const forTeacherUseOnlyText = computed(() => fmtMsg(TeacherClass.ForTeacherUseOnly));

    watch(
      pencilPath,
      () => {
        renderPolylineAsLineOnCanvas(pencilPath.value, canvas, studentOneAndOneId.value, null);
      },
      { deep: true },
    );

    watch(
      laserPath,
      () => {
        renderPolylineAsLaserOnCanvas(laserPath, canvas, !!studentOneAndOneId.value, studentOneAndOneId.value, undefined, SCALE_RATIO);
      },
      { deep: true },
    );

    const mediaState = computed(() => getters["teacherRoom/getMediaState"]);
    const currentTimeMedia = computed(() => getters["teacherRoom/getCurrentTimeMedia"]);

    watch(
      mediaState,
      () => {
        if (!isHelper.value) {
          return;
        }
        if (video.value) {
          mediaState.value === true ? video.value.play() : video.value.pause();
        }
        if (audio.value) {
          mediaState.value === true ? audio.value.play() : audio.value.pause();
        }
      },
      { deep: true },
    );

    watch(
      currentTimeMedia,
      () => {
        if (!isHelper.value) {
          return;
        }
        if (video.value && currentTimeMedia.value) {
          video.value.currentTime = currentTimeMedia.value;
        }
        if (audio.value && currentTimeMedia.value) {
          audio.value.currentTime = currentTimeMedia.value;
        }
      },
      { deep: true },
    );

    // render pdf
    const pdfRef = ref<HTMLElement | null>(null);
    const pdfHeight = ref<number[]>([]);
    const onPdfRendered = () => {
      pdfHeight.value = [];
      document.querySelectorAll(".pdf-config canvas").forEach((element) => {
        pdfHeight.value.push(element.clientHeight);
      });
      if (pdfScrollProgress.value) {
        scrollPdfToPercentage(pdfScrollProgress.value, false);
      }
    };

    // when teacher scrolls pdf, send scroll percentage to remote users
    const { y: scrollValue, isScrolling } = useScroll(pdfRef);
    watch(isScrolling, async (currentValue, oldValue) => {
      if (isHelper.value) {
        return;
      }

      let scrollPercentage = 0;
      let scrollHeight = scrollValue.value;
      for (const hPage of pdfHeight.value) {
        if (scrollHeight >= hPage) {
          scrollPercentage += 100;
          scrollHeight -= hPage;
        } else if (scrollHeight > 0 && scrollHeight < hPage) {
          scrollPercentage += Number(((scrollHeight / hPage) * 100).toFixed(2));
          scrollHeight = 0;
        }
      }

      if (!currentValue && oldValue) {
        await dispatch("lesson/setPdfScrollProgress", scrollPercentage);
        await dispatch("teacherRoom/updateRemotePdfScrollProgress", scrollPercentage);
      }
    });

    // for helper, when teacher scrolls pdf, scroll file to that position
    const pdfScrollProgress = computed(() => getters["lesson/pdfScrollProgress"]);
    const scrollPdfToPercentage = (percentage: number, isSmooth = true) => {
      const pdfElement = document.querySelector(".pdf-config");
      let currentPage = 0;
      let scrollHeight = 0;
      while (percentage > 0) {
        if (percentage >= 100) {
          scrollHeight += pdfHeight.value[currentPage];
          percentage -= 100;
          currentPage++;
        } else {
          scrollHeight += (pdfHeight.value[currentPage] * percentage) / 100;
          percentage = 0;
          currentPage++;
        }
      }
      pdfElement?.scrollTo({
        top: scrollHeight,
        behavior: isSmooth ? "smooth" : "auto",
      });
    };
    watch(pdfScrollProgress, (currentValue) => {
      if (!isHelper.value) {
        return;
      }
      scrollPdfToPercentage(currentValue);
    });

    // handle audio background
    const audioLocalTime = ref(getters["teacherRoom/getCurrentTimeMedia"]); // audio's actual current time, not from state, in seconds
    const onAudioTimeUpdate = () => {
      audioLocalTime.value = audio.value?.currentTime ?? 0;
    };

    const audioBackground = computed(() => {
      const backgrounds = props.image?.audioBackground;
      if (mediaTypeId.value !== MediaType.mp3 || !backgrounds || !backgrounds.length) {
        return undefined;
      }
      return [...backgrounds];
    });

    const currentAudioBackgroundUrl = computed(() => {
      if (!audioBackground.value) {
        return undefined;
      }
      const currentBackground =
        audioBackground.value?.find((media) => media?.pageTimingToMillisecond && media.pageTimingToMillisecond > audioLocalTime.value * 1000) ??
        audioBackground.value?.[audioBackground.value?.length - 1];
      return currentBackground.image?.url;
    });

    const closeTextBoxControl = async () => {
      toolSelected.value = Tools.Laser;
      canvas.isDrawingMode = true;
      await setDrawMode();
    };

    watch(controlTextState, async (val) => {
      if (isControlTextStateWatcherActive) {
        await updateSelectedObjectStyles(canvas, val);
      }
    });

    watch(
      () => props.image?.url,
      async () => {
        // Run before old videoRef/audioRef is removed
        if (video.value) {
          video.value.oncanplay = null;
        }
        if (audio.value) {
          audio.value.oncanplay = null;
        }
        toggleMediaCanPlay(false);
        await nextTick();
        // Run after new videoRef/audioRef is created
        if (video.value) {
          videoRef.value = video.value;
          video.value.oncanplay = (e) => {
            toggleMediaCanPlay(true);
          };
        }
        if (audio.value) {
          audioRef.value = audio.value;
          audio.value.oncanplay = (e) => {
            toggleMediaCanPlay(true);
          };
        }
      },
    );

    const onErrorLoadSlide = () => {
      canvas.getObjects().forEach((obj: any) => {
        if (obj.type === "group") {
          canvas.remove(obj);
        }
      });
      handleImageLoadError();
    };

    const speakerDeviceId = computed(() => getters["speakerDeviceId"]);

    const setSinkId = async () => {
      if (mediaTypeId.value === MediaType.mp4) {
        const videoEl = document.getElementById(ALTERNATE_VIDEO_ID) as HTMLVideoElement;
        if (typeof videoEl?.setSinkId === "function") {
          await videoEl?.setSinkId(speakerDeviceId.value);
        }
      } else if (mediaTypeId.value === MediaType.mp3) {
        const audioEl = document.getElementById(ALTERNATE_AUDIO_ID) as HTMLAudioElement;
        if (typeof audioEl?.setSinkId === "function") {
          await audioEl?.setSinkId(speakerDeviceId.value);
        }
      }
    };

    watch(speakerDeviceId, setSinkId);

    return {
      MsgShowAllTargetsBtn: computed(() => fmtMsg(TeacherClass.ShowAllTargets)),
      MsgHideAllTargetsBtn: computed(() => fmtMsg(TeacherClass.HideAllTargets)),
      currentExposureItemMedia,
      mediaTypeId,
      MediaType,
      clickedTool,
      toolNames,
      toolSelected,
      strokeWidth,
      strokeColor,
      updateColorValue,
      updateStrokeWidth,
      showWhiteboard,
      hideWhiteboard,
      onLPSlideLoaded,
      hasTargets,
      targetsNum,
      targetText,
      handleClickToggleAllTargetsBtn,
      zoomIn,
      zoomOut,
      showHidePreviewModal,
      disablePreviewBtn,
      zoomPercentage,
      isTeacherUseOnly,
      focusWordContent,
      forTeacherUseOnlyText,
      video,
      audio,
      isValidUrl,
      isHelper,
      imgRef,
      imgCropRef,
      whiteBoardRef,
      isCropImage,
      isPointerMode,
      pointerStyle,
      pdfRef,
      onPdfRendered,
      ShowWhiteboardText,
      HideWhiteboardText,
      PreviewTargetText,
      WarningAudioTagText,
      WarningVideoTagText,
      currentAudioBackgroundUrl,
      onAudioTimeUpdate,
      deactiveObject,
      clearObject,
      handleMouseOver,
      handleMouseOut,
      isWbVisible,
      isWhiteboardShrink,
      isTextBoxSelected,
      closeTextBoxControl,
      controlTextState,
      updateControlTextState,
      reloadKey,
      onErrorLoadSlide,
      isAllTargetsOnCanvasVisible,
      isAllTargetsOnCanvasTransparent,
      DEFAULT_ALTERNATE_CONTENT_VOLUME,
      ALTERNATE_VIDEO_ID,
      ALTERNATE_AUDIO_ID,
    };
  },
});
