import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "my-4 flex-1 relative",
  ref: "scrollElement"
}
const _hoisted_2 = { class: "absolute inset-0 overflow-auto" }
const _hoisted_3 = {
  key: 0,
  class: "w-full mt-2 text-center border border-dotted rounded-xl p-4 border-slate-400 text-slate-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentCard = _resolveComponent("StudentCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.totalOnlineStudents)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.MsgNoStudentOnline), 1))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            ref: "studentGallery",
            id: 'student-list',
            class: _normalizeClass(["grid gap-x-2 gap-y-2", [_ctx.oneWithTeacherStudentId ? 'grid-cols-1' : _ctx.studentLayoutClass]])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topStudents, (student, index) => {
              return (_openBlock(), _createBlock(_component_StudentCard, {
                key: student.id,
                scaleOption: _ctx.scaleVideoOption,
                student: student,
                setModeOne: true,
                focusStudentId: _ctx.focusedStudent,
                class: _normalizeClass(_ctx.getTransformOriginClass(index))
              }, null, 8, ["scaleOption", "student", "focusStudentId", "class"]))
            }), 128))
          ], 2))
    ])
  ], 512))
}