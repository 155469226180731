import { VCPlatform } from "@/store/app/state";
import { GLServiceBase } from "vue-glcommonui";
import { HelperServiceInterface } from "./interface";
import {
  CancelJoinAsHelperResponse,
  GetSessionStatusResponse,
  HelperExitResponse,
  HelperRequestTeachingResponse,
  JoinSessionAsHelperResponse,
  TeacherAcceptHelperResponse,
  TeacherDenyHelperResponse,
  TeacherRemoveHelperResponse,
  TeacherToggleHelperVideo,
  ToggleHelperCameraResponse,
  ToggleHelperMicroResponse,
} from "./model";
import { cloneDeep } from "lodash";
import { FetchSessionDtoResModel } from "@/services";
export const transformOneToOneDataServeHelper = (model: FetchSessionDtoResModel) => {
  const cloned = cloneDeep(model);
  if (!cloned.data) {
    return cloned;
  }
  cloned.data.oneToOneWithAnotherTeacherStudentId = cloned.data.studentOneToOne;
  cloned.data.studentOneToOne = cloned.data.studentHelperOneToOne;
  cloned.data.oneAndOneDto = cloned.data.helperOneAndOneDto;
  return cloned;
};
export const transformOneToOneDataServeTeacher = (model: FetchSessionDtoResModel) => {
  const cloned = cloneDeep(model);
  if (!cloned.data) {
    return cloned;
  }
  cloned.data.oneToOneWithAnotherTeacherStudentId = cloned.data.studentHelperOneToOne || "";
  return cloned;
};
class HelperServiceClass extends GLServiceBase<any, any> implements HelperServiceInterface {
  serviceRoute = { prefix: "remote/v1" };
  getSessionStatus(groupId: string): Promise<GetSessionStatusResponse> {
    return this.get("helper/session-status", { groupId });
  }
  joinSessionAsHelper(groupId: string, platform: VCPlatform, deviceId: string): Promise<JoinSessionAsHelperResponse> {
    return this.get("helper/request-join-session", { groupId, platform, deviceId });
  }
  cancelJoinAsHelperRequest(groupId: string): Promise<CancelJoinAsHelperResponse> {
    return this.update(`helper/cancel-request?groupId=${groupId}`);
  }
  fetchSessionDataAsHelper(groupId: string, deviceId = ""): Promise<FetchSessionDtoResModel> {
    return this.get("helper/session-data", { groupId, deviceId }).then((res) => transformOneToOneDataServeHelper(res as FetchSessionDtoResModel));
  }
  exitSession(): Promise<HelperExitResponse> {
    return this.update("helper/exit-session");
  }
  teacherAcceptHelper(helperId: string, helperName: string, deviceId: string): Promise<TeacherAcceptHelperResponse> {
    return this.create("teacher/accept-helper", { helperId, helperName, deviceId });
  }
  teacherDenyHelper(helperId: string): Promise<TeacherDenyHelperResponse> {
    return this.create(`teacher/deny-helper?helperId=${helperId}`);
  }
  teacherToggleHelperVideo(show: boolean): Promise<TeacherToggleHelperVideo> {
    return this.create(`teacher/toggle-helper-video?show=${show}`);
  }
  teacherRemoveHelper(helperId: string): Promise<TeacherRemoveHelperResponse> {
    return this.get(`teacher/remove-helper`, { helperId });
  }
  // obsolete
  toggleHelperMicro(isOff: boolean, sessionId: string): Promise<ToggleHelperMicroResponse> {
    return this.update(`helper/toggle-audio?sessionId=${sessionId}&isMute=${isOff}`);
  }
  // obsolete
  toggleHelperCamera(isOff: boolean, sessionId: string): Promise<ToggleHelperCameraResponse> {
    return this.update(`helper/toggle-video?sessionId=${sessionId}&isMute=${isOff}`);
  }
  requestTeaching(): Promise<HelperRequestTeachingResponse> {
    return this.create("helper/request-teaching");
  }
}

export const HelperService = new HelperServiceClass();
