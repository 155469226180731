import * as medal from "@/assets/lotties/medal.json";
import { CommonLocale, DeviceTesterLocale, IndependentLocales, SessionReplacementLocales, StoreLocale } from "@/locales/localeid";
import { AnnotationMode, HelperModel, RoomModel, StudentModel, TeacherModel } from "@/models";
import { GLErrorCode } from "@/models/error.model";
import { IndependentStudentItem } from "@/services/independent";
import { FabricUpdateType, Pointer, UserShape } from "@/store/annotation/state";
import { VCPlatform } from "@/store/app/state";
import { Target } from "@/store/interactive/state";
import { ExposureStatus, TeachingActivityTarget } from "@/store/lesson/state";
import { TeachingMode } from "@/store/teaching/interfaces";
import { MIN_ZOOM_RATIO } from "@/utils/constant";
import { FabricObject, WSEventHandler } from "@/ws";
import { Modal, notification } from "ant-design-vue";
import { reactive } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { ActionContext } from "vuex";
import {
  InClassStatus,
  OneToOneModeResponse,
  OnTeacherClearRaisingHand,
  OnTeacherSetLessonPlanItemContent,
  OnTeacherToggleStudentPalette,
  OnTeacherUpdateStudentBadgeItem,
  StudentState,
  TeacherSetMediaStateReceiveResponse,
} from "../interface";
import { ClassActionFromValue, StudentRoomState } from "./state";
import router from "@/router";
import { RemoteTeachingService, ToggleStudentMediaDevicesAsync } from "@/services";
import { Logger } from "@/utils/logger";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";
import { isTeacherUseOnlySlideVisible, ObjectTypes, OperationTypes } from "@/utils/utils";
import { IEditLine } from "@/store/annotation/mutations";
import { vuexName, VuexNames } from "@/store/utils";
import { calculatePlayedTime } from "@/utils/convertDuration";
import { notifyStudentJoinedOnDifferentDevice } from "@/utils/notifications";
import { Paths } from "@/utils/paths";

export const useStudentRoomHandler = (store: ActionContext<StudentRoomState, any>): WSEventHandler => {
  const { commit, dispatch, state, getters, rootGetters } = store;
  const timestamps = {
    onTeacherSetLessonPlanItemContentTimestamp: 0,
  };
  const handler = {
    onStudentJoinClass: async (payload: StudentModel) => {
      const isMe = getters["localStudentId"] === payload.id;
      const isIndependentMode = rootGetters["classTeaching/isIndependentMode"];
      if (isIndependentMode && isMe) {
        notification.info({ message: fmtMsg(IndependentLocales.StartMessage) });
      }
      commit("setStudentConnectionStatus", {
        id: payload.id,
        status: payload.connectionStatus,
      });
      commit("updateRaisingHand", {
        id: payload.id,
        isRaisingHand: payload.isRaisingHand,
      });
      commit("updateIsPalette", {
        id: payload.id,
        isPalette: payload.isPalette,
      });
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onStudentLeave: (payload: StudentModel) => {
      commit("setStudentConnectionStatus", {
        id: payload.id,
        status: payload.connectionStatus,
      });
      commit("clearCircleStatus", { id: payload.id });
      dispatch("updateAudioAndVideoFeed", {});
    },
    // TODO: Remove in re-engineering
    onStudentDisconnected: async (payload: StudentModel) => {
      //
    },
    // TODO: Remove in re-engineering
    onStudentStreamConnect: (_payload: any) => {
      //   Logger.log(_payload);
    },
    // TODO: Remove in re-engineering
    onStudentSendUnity: (payload: any) => {
      //   Logger.log(payload);
    },
    // TODO: Remove in re-engineering
    onStudentMuteAudio: (payload: StudentModel) => {
      //
    },
    // Obsolete
    onStudentMuteVideo: (payload: StudentModel) => {
      commit("setStudentVideo", {
        id: payload.id,
        enable: !payload.isMuteVideo,
      });
      if (state.student?.id === payload.id) return;
      dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherJoinClass: async (payload: TeacherModel) => {
      if (!state.teacher) return;
      commit("setTeacherConnectionStatus", {
        id: state.teacher.id,
        status: InClassStatus.JOINED,
      });
    },
    // Obsolete
    onTeacherStreamConnect: (_payload: any) => {
      dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherToggleStudentPalette: async (payload: OnTeacherToggleStudentPalette) => {
      commit("setStudentPalette", payload);
    },
    onTeacherEndClass: async (_payload: any) => {
      commit("setIsSessionEnded", true);
      await dispatch("leaveRoom", { leave: true });
      commit("setApiStatus", {
        code: GLErrorCode.CLASS_HAS_BEEN_ENDED,
        message: fmtMsg(CommonLocale.CommonEndSession),
      });
      Modal.destroyAll();
    },
    onTeacherDisconnect: async (payload: any) => {
      // commit("setTeacherConnectionStatus", {
      //   id: payload.id,
      //   status: InClassStatus.DEFAULT,
      // });
      // await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherSetTeachingMode: async (payload: number) => {
      //
    },
    onTeacherUpdateStudentBadge: async (payload: OnTeacherUpdateStudentBadgeItem[]) => {
      commit("setStudentsBadge", payload);

      const currentStudentId = state.student?.id;
      if (currentStudentId && payload.some((item) => item.id === currentStudentId)) {
        const showMedalNotification = () => {
          const icon = reactive({ animationData: medal.default });
          store.dispatch(
            "setToast",
            {
              message: "",
              isPlayingSound: true,
              bigIcon: icon,
              isMedal: true,
            },
            { root: true },
          );
        };
        showMedalNotification();
      }
    },
    onTeacherUpdateBlackOut: (payload: any) => {
      commit("lesson/setIsBlackOut", { IsBlackOut: payload.isBlackOut }, { root: true });
    },
    onTeacherStartLessonPlan: async (id: string) => {
      commit("lesson/setCurrentExposure", { id }, { root: true });
      await dispatch("annotation/clearAnnotationState", null, { root: true });
      commit("setWhiteboard", false);
    },
    onTeacherEndLessonPlan: (payload: { contentId: string; needUpdateRemainingTime: boolean }) => {
      commit("lesson/setExposureStatus", { id: payload.contentId, status: ExposureStatus.COMPLETED }, { root: true });
      if (payload.needUpdateRemainingTime) {
        const playedTime = calculatePlayedTime(rootGetters["lesson/exposures"]);
        commit("lesson/setPlayedTime", { time: playedTime }, { root: true });
      }
    },
    onTeacherSetLessonPlanItemContent: async ({ exposureItemId, timestamp }: OnTeacherSetLessonPlanItemContent) => {
      const previousTimestamp = timestamps.onTeacherSetLessonPlanItemContentTimestamp;
      if (previousTimestamp && timestamp < previousTimestamp) return;
      timestamps.onTeacherSetLessonPlanItemContentTimestamp = timestamp;
      commit("whiteboard/setShouldFetchMediaState", false, { root: true });
      commit("lesson/setCurrentExposureItemMedia", { id: exposureItemId }, { root: true });
      await dispatch("annotation/clearAnnotationState", null, { root: true });
      await dispatch("lesson/setPdfScrollProgress", 0, { root: true });
      await dispatch("lesson/setImgCoords", { x: 0, y: 0 }, { root: true });
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, { root: true });
      await dispatch("annotation/setClearBrush", {}, { root: true });
      await dispatch("annotation/clearPencilPath", null, { root: true });
      commit("annotation/setDrawings", [], { root: true });
      commit("setWhiteboard", false);
      commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS), [], { root: true });
    },
    onTeacherClearRaisingHand: ({ id }: OnTeacherClearRaisingHand) => {
      commit("setStudentRaisingHand", {
        id,
        isRaisingHand: false,
      });
    },
    onTeacherUpdateClassAction: (payload: { action: number }) => {
      commit(
        "studentRoom/setClassAction",
        {
          action: ClassActionFromValue(payload.action),
        },
        { root: true },
      );
    },
    onTeacherDesignateTarget: async (payload: any) => {
      await dispatch("interactive/setInfo", payload, { root: true });
      const isAssigned = rootGetters["interactive/isAssigned"];
      if (isAssigned) {
        notification.open({
          message: fmtMsg(StoreLocale.ClickBoardToAnswer),
        });
      }
    },
    onTeacherUpdateDesignateTarget: async (payload: any) => {
      await dispatch("interactive/setInfo", payload, { root: true });
    },
    onStudentAnswerSelf: async (payload: Array<Target>) => {
      await dispatch(
        "interactive/setRevealedLocalTarget",
        payload.map((s) => s.id),
        { root: true },
      );
    },
    onStudentAnswerAll: async (payload: Target) => {
      await dispatch("interactive/setRevealedTarget", payload.id, {
        root: true,
      });
    },
    onStudentUpdateAnswers: async (payload: any) => {
      //   Logger.log(payload);
    },
    onTeacherSetPointer: async (payload: Pointer) => {
      const isPointer = rootGetters["annotation/isPointerMode"];
      if (!isPointer) await dispatch("annotation/setMode", { mode: AnnotationMode.PointerMode }, { root: true });
      await dispatch("annotation/setPointer", payload, {
        root: true,
      });
    },
    onTeacherUpdateAnnotationMode: async (payload: number) => {
      await dispatch("annotation/setMode", { mode: payload }, { root: true });
    },
    onTeacherAddBrush: async (payload: string) => {
      await dispatch("annotation/addShape", payload, {
        root: true,
      });
    },
    onTeacherClearAllBrush: async () => {
      commit("annotation/setDrawings", [], { root: true });
      await dispatch("annotation/setClearBrush", {}, { root: true });
      await dispatch("annotation/clearPencilPath", null, { root: true });
    },
    onTeacherDeleteBrush: async (payload: any) => {
      await dispatch("annotation/setDeleteBrush", {}, { root: true });
    },
    onTeacherSetStickers: async (payload: any) => {
      await dispatch(
        "annotation/setStickers",
        { stickers: payload },
        {
          root: true,
        },
      );
    },
    onTeacherClearStickers: async (payload: any) => {
      await dispatch("annotation/setClearStickers", { stickers: [] }, { root: true });
    },
    // TODO: Remove in re-engineering
    onTeacherSendUnity: async (payload: any) => {
      await dispatch(
        "unity/setTeacherMessage",
        { message: payload },
        {
          root: true,
        },
      );
    },
    onTeacherSetOneToOne: async (payload: OneToOneModeResponse) => {
      store.commit("classTeaching/setMyOneAndOneData", null, { root: true });
      const roomManager = state.manager;
      const { enable, studentOneToOneId } = payload;
      const { student, idHelperOne } = state;
      const isMeInOneToOneWithHelper = student?.id === idHelperOne;
      if (!student) return;
      if (isMeInOneToOneWithHelper) {
        const studentOneId = enable ? studentOneToOneId : "";
        await dispatch("setStudentOneId", { id: studentOneId, withTeacher: true });
        return;
      }
      const isMeOneToOne = student.id === studentOneToOneId;
      const usingZoom = rootGetters["platform"] === VCPlatform.Zoom;
      if (enable) {
        //** Handle when enter one-one mode */
        await dispatch(
          "studentRoom/setStudentOneId",
          { id: studentOneToOneId, withTeacher: true },
          {
            root: true,
          },
        );
        isMeOneToOne && commit("annotation/cloneAnnotationsToOneOne", null, { root: true });
        if (usingZoom) {
          isMeOneToOne && (await roomManager?.zoomClient.studentJoinOneToOneSubSession());
        } else {
          await dispatch("updateAudioAndVideoFeed", {});
        }
      } else {
        //** Handle when exit one-one mode */
        try {
          if (!isMeOneToOne) {
            //Student who not join one-one mode will not update any data further
            await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: true }, { root: true });
            await dispatch("updateAudioAndVideoFeed", {});
            return;
          }
          //Handle for student who have joined one-one mode with teacher
          const { data } = await RemoteTeachingService.getMinimalSessionDto(rootGetters["classTeaching/getSessionId"], student.id);
          await dispatch("teams/getTeams", null, { root: true });
          commit("teams/setIsTeamMode", data.isTeamMode, { root: true });
          await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: true }, { root: true });
          if (usingZoom) {
            await roomManager?.zoomClient.backToMainSession();
          }
          await dispatch("updateAudioAndVideoFeed", {});
          await dispatch("annotation/setFabricsInOneMode", [], { root: true });
          await dispatch("lesson/setZoomRatio", data.ratio, {
            root: true,
          });
          await dispatch("lesson/setPdfScrollProgress", data.ratioScrollPdf, {
            root: true,
          });
          if (data.position) {
            await dispatch("lesson/setImgCoords", { x: data.position.x, y: data.position.y }, { root: true });
          }
          commit(
            "lesson/setCurrentExposure",
            {
              id: data.exposureSelected,
              preventSelectFirstSlideAutomatically: !!data.itemContentSelected,
            },
            { root: true },
          );
          if (data.itemContentSelected) {
            commit("lesson/setCurrentExposureItemMedia", { id: data.itemContentSelected }, { root: true });
          }
          commit("updateIsPalette", {
            id: studentOneToOneId,
            isPalette: data.isPalette,
          });
          commit("setWhiteboard", data.isShowWhiteBoard);
          if (
            !isTeacherUseOnlySlideVisible({
              isTeacherUseOnly: !!rootGetters["lesson/currentExposureItemMedia"]?.teacherUseOnly,
              isWhiteboardVisible: data.isShowWhiteBoard,
            })
          ) {
            await dispatch("annotation/setTeacherBrushes", data.drawing?.brushstrokes, { root: true });
            await dispatch("annotation/setTeacherAddShape", { teacherShapes: data.drawing?.shapes }, { root: true });
            await dispatch("annotation/setLastFabricUpdated", null, {
              root: true,
            });
            commit("annotation/setDrawings", data.drawing?.fabrics ?? [], { root: true });
          }
          await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), data.drawing?.visibleShapes, {
            root: true,
          });
          if (data.independentMode) {
            commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
          } else {
            commit("whiteboard/setShouldFetchMediaState", true, { root: true });
            commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
          }
        } catch (err) {
          Logger.log(err);
        }
      }
    },
    onTeacherFailSetOneToOne: (payload: any) => {
      //
    },
    onHelperSetOneToOne: async (payload: OneToOneModeResponse) => {
      store.commit("classTeaching/setMyOneAndOneData", null, { root: true });
      const { enable, studentOneToOneId } = payload;
      const { student, idOne } = state;
      if (!student) return;
      const isMeInOneToOneWithTeacher = student?.id === idOne;
      if (isMeInOneToOneWithTeacher) {
        const studentOneId = enable ? studentOneToOneId : "";
        await dispatch("setStudentOneId", { id: studentOneId, withTeacher: false });
        return;
      }
      const isMeOneToOne = studentOneToOneId === student.id;
      if (enable) {
        //** Handle when enter oneone mode */
        /* Best case scenario is we have to keep all the state remaining */
        await dispatch(
          "studentRoom/setStudentOneId",
          { id: studentOneToOneId, withTeacher: false },
          {
            root: true,
          },
        );
        //* muted teacher's voice when enter 1:1
        if (isMeOneToOne) {
          commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", true, { root: true });
        }
        await dispatch("updateAudioAndVideoFeed", {});
      } else {
        //** Handle when exit oneone mode */
        try {
          if (!isMeOneToOne) {
            //Student who not join one one mode will not update any data further
            await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: false }, { root: true });
            await dispatch("updateAudioAndVideoFeed", {});
            return;
          }
          //Handle for student who have joined one one mode with teacher
          const { data } = await RemoteTeachingService.getMinimalSessionDto(rootGetters["classTeaching/getSessionId"], student.id);
          await dispatch("teams/getTeams", null, { root: true });
          commit("teams/setIsTeamMode", data.isTeamMode, { root: true });
          await dispatch("studentRoom/setStudentOneId", { id: "", withTeacher: false }, { root: true });
          commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", false, { root: true });
          await dispatch("updateAudioAndVideoFeed", {});
          await dispatch("lesson/setZoomRatio", data.ratio, {
            root: true,
          });
          await dispatch("lesson/setPdfScrollProgress", data.ratioScrollPdf, {
            root: true,
          });
          if (data.position) {
            await dispatch("lesson/setImgCoords", { x: data.position.x, y: data.position.y }, { root: true });
          }
          commit(
            "lesson/setCurrentExposure",
            {
              id: data.exposureSelected,
              preventSelectFirstSlideAutomatically: true,
            },
            { root: true },
          );
          commit("lesson/setCurrentExposureItemMedia", { id: data.itemContentSelected ?? "" }, { root: true });
          commit("updateIsPalette", {
            id: studentOneToOneId,
            isPalette: data.isPalette,
          });
          commit("setWhiteboard", data.isShowWhiteBoard);
          await dispatch("annotation/clearPencilPath", null, { root: true });
          if (
            !isTeacherUseOnlySlideVisible({
              isTeacherUseOnly: !!rootGetters["lesson/currentExposureItemMedia"]?.teacherUseOnly,
              isWhiteboardVisible: data.isShowWhiteBoard,
            })
          ) {
            await dispatch("annotation/setTeacherBrushes", data.drawing?.brushstrokes, { root: true });
            await dispatch("annotation/setTeacherAddShape", { teacherShapes: data.drawing?.shapes }, { root: true });
            await dispatch("annotation/setLastFabricUpdated", null, {
              root: true,
            });
            commit("annotation/setDrawings", data.drawing?.fabrics ?? [], { root: true });
          }
          await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), data.drawing?.visibleShapes, {
            root: true,
          });
          await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), data.drawing?.visibleShapes, { root: true });
          commit("lesson/setPlayedTime", { time: data.playedTime }, { root: true });
          commit("whiteboard/setShouldFetchMediaState", true, { root: true });
          if (data.independentMode) {
            commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
          } else {
            commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
          }
        } catch (err) {
          Logger.log(err);
        }
      }
    },
    onHelperFailSetOneToOne: (payload: any) => {
      //
    },
    onTeacherSetWhiteboard: async (payload: any) => {
      commit("setWhiteboard", payload);
    },
    onTeacherSetMediaState: async (payload: TeacherSetMediaStateReceiveResponse) => {
      const { startMediaAt, duration, pauseMediaAt } = payload;
      commit("whiteboard/setMediaState", { startMediaAt, duration, pauseMediaAt }, { root: true });
    },
    onTeacherSetCurrentTimeMedia: async (payload: any) => {
      commit("setCurrentTimeMedia", payload);
    },
    onStudentSetBrushstrokes: async (payload: Array<UserShape>) => {
      await dispatch("annotation/setStudentAddShape", { studentShapes: payload }, { root: true });
    },
    onTeacherAddShape: async (payload: any) => {
      await dispatch("annotation/setTeacherAddShape", { teacherShapes: payload }, { root: true });
    },
    onStudentDrawsLine: async (payload: string) => {
      //
    },
    onTeacherCreateFabricObject: async (payload: FabricObject) => {
      await dispatch(
        "annotation/setLastFabricUpdated",
        {
          type: FabricUpdateType.CREATE,
          data: payload,
        },
        { root: true },
      );
    },
    onTeacherModifyFabricObject: async (payload: FabricObject) => {
      await dispatch(
        "annotation/setLastFabricUpdated",
        {
          type: FabricUpdateType.MODIFY,
          data: payload,
        },
        { root: true },
      );
    },
    onToggleTarget: async (payload: TeachingActivityTarget) => {
      if (payload.userId === state.student?.id) return;
      await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE), payload, { root: true });
    },
    onTeacherUpdateSessionLessonAndUnit: async () => {
      await dispatch("lesson/setZoomRatio", 1, { root: true });
      await dispatch("lesson/setPdfScrollProgress", 0, { root: true });
      await dispatch("lesson/setImgCoords", undefined, { root: true });
      commit({ type: "lesson/clearLessonData" }, { root: true });
      await dispatch("getClassRoomInfo");
    },
    onRoomInfo: async (payload: RoomModel) => {
      //
    },
    onTeacherZoomSlide: async (p: { ratio: number; position: { x: number; y: number } }) => {
      await dispatch("lesson/setZoomRatio", p.ratio, { root: true });
      await dispatch("lesson/setImgCoords", { x: p.position.x, y: p.position.y }, { root: true });
    },
    onTeacherMoveZoomedSlide: async (
      p: {
        x: number;
        y: number;
        viewPortX: number;
        viewPortY: number;
      } | null,
    ) => {
      await dispatch("lesson/setImgCoords", p ? { x: p.x, y: p.y } : undefined, { root: true });
    },
    onTeacherResetZoom: async (p: any) => {
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, {
        root: true,
      });
    },
    onTeacherDrawPencil: async (p: string) => {
      await dispatch("annotation/setDrawPencil", p, { root: true });
    },
    onTeacherDrawLaser: async (p: any) => {
      await dispatch("annotation/setDrawLaser", p, { root: true });
    },
    // onTeacherResetPaletteAllStudent: (p: boolean) => {
    //   commit("studentRoom/disableAllAnnotationStatus", p, { root: true });
    // },
    onTeacherDeleteFabric: async (payload: any) => {
      if (payload.fabricId) {
        commit("annotation/setLastFabricIdDeleted", payload.fabricId, { root: true });
      }
      // await dispatch("annotation/setDeleteFabric", {}, { root: true });
    },
    onTeacherDeleteShape: async (payload: any) => {
      await dispatch("annotation/setDeleteShape", {}, { root: true });
    },
    onHelperRequestJoinClass: async (payload: any) => {
      //
    },
    onHelperCancelRequestJoinClass: async (payload: any) => {
      //
    },
    onHelperJoinedClass: async (payload: HelperModel) => {
      commit("setHelperInfo", payload);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onHelperExitClass: async (payload: any) => {
      commit("setHelperInfo", undefined);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onHelperDisconnectClass: async (payload: any) => {
      //OK
      // commit("setHelperConnectionStatus", HelperInClassStatus.Disconnected);
      // await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherHideHelperVideo: async () => {
      commit("setHelperVideoStatus", false);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherShowHelperVideo: async () => {
      commit("setHelperVideoStatus", true);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherRemoveHelper: async () => {
      // Remove helper state
      commit("setHelperInfo", undefined);
    },
    // Obsolete
    onHelperToggleCamera: async (isOff: boolean) => {
      commit("setHelperCameraStatus", isOff);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    // Obsolete
    onHelperToggleMicro: async (isOff: boolean) => {
      commit("setHelperMicroStatus", isOff);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    // Obsolete
    onTeacherRequestStudentReConnectVideo: async () => {
      await dispatch("checkAndReconnectVideo", {});
    },
    onStudentRaisingHand: async (student: StudentModel) => {
      //
    },
    onTeacherToggleIndependentMode: async (firstItemId: string | null) => {
      if (firstItemId) {
        notification.info({ message: fmtMsg(IndependentLocales.StartMessage) });
        commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
        commit("studentTeaching/setCurrentIndependentItemId", firstItemId, { root: true });
      } else {
        notification.info({ message: fmtMsg(IndependentLocales.StopMessage) });
        commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
        commit("studentTeaching/setCurrentIndependentItemId", null, { root: true });
      }
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onStudentSelectItemInIndependentMode: (payload: IndependentStudentItem) => {
      //
    },
    onHelperBecomeTeacher: async () => {
      commit("studentRoom/makeHelperTheNewTeacher", {}, { root: true });
      if (rootGetters["platform"] === VCPlatform.Zoom) {
        const student: StudentState = rootGetters["studentRoom/student"];
        const classInfo: RoomModel = rootGetters["studentRoom/info"];
        await router.push({ path: `/student/${student.id}/class/${classInfo.classInfo.classId}`, query: { ts: Date.now() } });
      } else {
        await dispatch("updateAudioAndVideoFeed", {});
      }
    },
    onOneToOneWithHelperIgnoreTeacherVoice: async (ignore: boolean) => {
      const { student, idHelperOne } = state;
      if (student?.id === idHelperOne) {
        commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", ignore, { root: true });
        await dispatch("updateAudioAndVideoFeed", {});
      }
    },
    onTeacherScrollPdf: (progress: number) => {
      commit("lesson/setPdfScrollProgress", progress, { root: true });
    },
    onToggleTeamMode: async (payload: boolean) => {
      commit("teams/setIsTeamMode", payload, { root: true });
    },
    onEditTeam: async () => {
      await dispatch("teams/getTeams", null, { root: true });
    },
    onResetTeam: async () => {
      await dispatch("teams/getTeams", null, { root: true });
    },
    onTeacherUpdateCanvasObject: async (payload: CanvasObjectModel) => {
      const { type, id, operation, metadata } = payload;
      if (type === ObjectTypes.Line) {
        commit("annotation/removePolyline", id, { root: true });
        if (operation === OperationTypes.Remove) {
          // use dispatch because we have two different variables for lines: lines and lines for one-one mode,
          // and we can't determine which one to use in mutation file
          await dispatch("annotation/removeLine", id, { root: true });
        } else if (operation === OperationTypes.Edit && metadata) {
          // use dispatch because we have two different variables for lines: lines and lines for one-one mode,
          // and we can't determine which one to use in mutation file
          const payload: IEditLine = { objectId: id, metadata };
          await dispatch("annotation/editLine", payload, { root: true });
        }
      }
      commit("object/setNextObjectToBeUpdated", payload, { root: true });
    },
    onTeacherToggleStudentMediaDevices: async (payload: ToggleStudentMediaDevicesAsync) => {
      await dispatch("handleTeacherToggleStudentMediaDevices", payload);
    },
    onJoinSessionOnDifferentDevice: async () => {
      notifyStudentJoinedOnDifferentDevice(() => {
        router.push(Paths.Parent);
      });
    },
  };

  return handler;
};
