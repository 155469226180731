import { CalendarSchedulesModel, ClassGroupModel, StudentGroupModel, TeacherClassCalendarModel, TeacherClassModel } from "@/models";
import { ResourceModel } from "@/models/resource.model";
import { UserModel } from "@/models/user.model";
import { FetchOngoingSessionModel } from "@/services";

export interface CalendarFilter {
  classId: string;
  groupId: string;
  date: string;
  isShowWeekends: boolean;
}
export interface TeacherState {
  info?: UserModel;
  schools: Array<ResourceModel>;
  classes: Array<TeacherClassCalendarModel>;
  classesSchedules: Array<TeacherClassModel>;
  classesSchedulesAllSchool: Array<TeacherClassModel>;
  acceptPolicy: boolean;
  calendarSchedules: Array<CalendarSchedulesModel>;
  currentSchoolId: string;
  classesSchedulesBySchools: Array<TeacherClassModel>;
  classSetUpStudents: Array<StudentGroupModel>;
  classGroup: Array<ClassGroupModel>;
  calendarFilter: CalendarFilter;
  isHelper: boolean;
  ignoreHelperRequestJoinClass: boolean;
  isHelperTakingOver: boolean;
  ongoingSession: FetchOngoingSessionModel | null;
}

const state: TeacherState = {
  info: undefined,
  schools: [],
  classes: [],
  acceptPolicy: true,
  calendarSchedules: [],
  classesSchedules: [],
  classesSchedulesAllSchool: [],
  currentSchoolId: "",
  classesSchedulesBySchools: [],
  classSetUpStudents: [],
  classGroup: [],
  calendarFilter: {
    classId: "",
    groupId: "",
    date: "",
    isShowWeekends: false,
  },
  //* Check current CLIENT joining class as teacher or helper */
  isHelper: false,
  ignoreHelperRequestJoinClass: false,
  isHelperTakingOver: false,
  ongoingSession: null,
};

export default state;
