import { Logger } from "@/utils/logger";
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { GLGlobal } from "vue-glcommonui";
import { HelperWSEvent, RoomWSEvent, StudentWSEvent, TeacherWSEvent } from "..";
import { WSEvent, WSEventHandler } from "@/ws";
import { store } from "@/store";
import { vuexName, VuexNames } from "@/store/utils";
export interface GLSocketOptions {
  url: string;
  reConnectedCallback: (newConnectionId: string) => Promise<any>;
  studentId?: string;
}

const DEFAULT_RECONNECT_TIMING = 5000;

export class GLSocketClient {
  private _hubConnection?: HubConnection;
  private readonly _options?: GLSocketOptions;
  private _isConnected = false;
  private _listener: any = {};

  constructor(options: GLSocketOptions) {
    this._options = options;
  }
  get hubConnection(): HubConnection {
    return this._hubConnection as HubConnection;
  }
  get options(): GLSocketOptions {
    return this._options as GLSocketOptions;
  }

  get signalrUrl(): string {
    const { url, studentId } = this.options;
    return studentId ? `${url}?studentId=${studentId}` : url;
  }

  async init() {
    if (this._hubConnection) return;
    const options = {
      accessTokenFactory: () => GLGlobal.loginInfo().access_token,
    };
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(this.signalrUrl, options)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => DEFAULT_RECONNECT_TIMING,
      })
      .configureLogging(LogLevel.Debug)
      .build();
    this._hubConnection.onclose(this.onClosed);

    // SignalR takes approximately 20 seconds to detect a lost connection using Ping-Pong (heartbeat) messages.
    // Messages sent during this time are queued but will fail once the connection is confirmed lost.
    // The "onreconnecting" event is triggered when SignalR detects the lost connection, and all messages sent during the disconnection period will fail.
    this._hubConnection.onreconnecting(() => {
      this.markAttemptedReconnecting(true);
      Logger.log("SIGNALR: Reconnecting...The connection has lost approximately 20 seconds");
    });

    this._hubConnection.onreconnected(async (connectionId) => {
      if (!connectionId) return;
      await this.options.reConnectedCallback(connectionId);
      this.markAttemptedReconnecting(false);
      Logger.log("SIGNALR: Auto reconnected successfully!", connectionId);
    });
    this._isConnected = false;
  }
  onClosed() {
    Logger.log("SIGNALR: Closed!");
    this._isConnected = false;
  }
  get isConnected(): boolean {
    return this._isConnected;
  }

  markAttemptedReconnecting = (isReconnecting: boolean) => {
    store.commit(vuexName(VuexNames.CLASS_TEACHING.COMMITS.SET_IS_SOCKET_ATTEMPTED_RECONNECTING), isReconnecting);
  };

  async disconnect(): Promise<void> {
    if (!this.isConnected) return;
    const keys = Object.keys(this._listener);
    keys.forEach((key) => {
      this.hubConnection.off(key);
    });
    return this._hubConnection?.stop();
  }
  async connect(isReconnectAttempt = false): Promise<any> {
    if (this._isConnected && this.hubConnection.state !== HubConnectionState.Disconnected) return Promise.resolve();
    if ([HubConnectionState.Connecting, HubConnectionState.Connected].includes(this.hubConnection.state)) return Promise.resolve();
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return Promise.resolve();
    }
    try {
      await this.init();
      await this.hubConnection.start();
      this._isConnected = true;
      if (isReconnectAttempt) {
        const newConnectionId = this.hubConnection.connectionId;
        await store.dispatch("teacherRoom/setSignalRConnectionId", newConnectionId);
      }
    } catch (error) {
      Logger.error(error);
      this._isConnected = false;
      return Promise.reject(error);
    }
  }
  async send(command: string, payload: any): Promise<any> {
    if (!this.hubConnection) {
      Logger.error("this.hubConnection: ", this.hubConnection);
    }
    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Connected) {
      Logger.error("CONNECTION STATE: " + this.hubConnection.state);
    }
    if (!this.isConnected || !this.hubConnection || !this.hubConnection.state || this.hubConnection.state === HubConnectionState.Disconnected) {
      Logger.error("SEND/TRY-TO-RECONNECT-MANUALLY");
      this._isConnected = false;
      await this.connect(true);
    }
    if (this.hubConnection.state === HubConnectionState.Connected) {
      return this.hubConnection.send(command, payload);
    } else return Promise.resolve();
  }

  async invoke(command: string, payload: any): Promise<any> {
    if (!this.hubConnection) {
      Logger.error("this.hubConnection: ", this.hubConnection);
    }
    if (this.hubConnection && this.hubConnection.state !== HubConnectionState.Connected) {
      Logger.error("CONNECTION STATE: " + this.hubConnection.state);
    }
    if (!this.isConnected || !this.hubConnection || !this.hubConnection.state || this.hubConnection.state === HubConnectionState.Disconnected) {
      Logger.error("INVOKE/TRY-TO-RECONNECT-MANUALLY");
      this._isConnected = false;
      await this.connect(true);
    }
    return this.hubConnection.invoke(command, payload);
  }

  registerEventHandler(handler: WSEventHandler) {
    const keys = Object.keys(this._listener);
    keys.forEach((key) => {
      this.hubConnection.off(key);
    });
    const handlers: Map<WSEvent, Function> = new Map<WSEvent, Function>();
    handlers.set(StudentWSEvent.JOIN_CLASS, handler.onStudentJoinClass);
    handlers.set(StudentWSEvent.STREAM_CONNECT, handler.onStudentStreamConnect);
	// Obsolete
    handlers.set(StudentWSEvent.MUTE_AUDIO, handler.onStudentMuteAudio);
	// Obsolete
    handlers.set(StudentWSEvent.MUTE_VIDEO, handler.onStudentMuteVideo);
    handlers.set(StudentWSEvent.LEAVE, handler.onStudentLeave);
    handlers.set(StudentWSEvent.DISCONNECT, handler.onStudentDisconnected);
    handlers.set(StudentWSEvent.EVENT_STUDENT_ANSWER_TARGET, handler.onStudentAnswerAll);
    handlers.set(StudentWSEvent.EVENT_STUDENT_ANSWER_CORRECT, handler.onStudentAnswerSelf);
	// Obsolete
    handlers.set(StudentWSEvent.EVENT_TEACHER_ANSWER_TARGET, handler.onStudentAnswerAll);
    handlers.set(StudentWSEvent.STUDENT_RAISING_HAND, handler.onStudentRaisingHand);
    handlers.set(StudentWSEvent.EVENT_STUDENT_UPDATE_SHAPE_LIST, handler.onStudentSetBrushstrokes);
    handlers.set(StudentWSEvent.EVENT_STUDENT_DRAWS_LINE, handler.onStudentDrawsLine);
    handlers.set(StudentWSEvent.EVENT_UPDATE_SHAPE, handler.onToggleTarget);
    handlers.set(StudentWSEvent.JOIN_SESSION_ON_DIFFERENT_DEVICE, handler.onJoinSessionOnDifferentDevice);
    handlers.set(HelperWSEvent.EVENT_HELPER_SET_ONE_TO_ONE, handler.onHelperSetOneToOne);
    handlers.set(HelperWSEvent.EVENT_HELPER_FAIL_SET_ONE_TO_ONE, handler.onHelperFailSetOneToOne);
    handlers.set(TeacherWSEvent.JOIN_CLASS, handler.onTeacherJoinClass);
    handlers.set(TeacherWSEvent.STREAM_CONNECT, handler.onTeacherStreamConnect);
    handlers.set(TeacherWSEvent.END_CLASS, handler.onTeacherEndClass);
    handlers.set(TeacherWSEvent.DISCONNECT, handler.onTeacherDisconnect);
    handlers.set(TeacherWSEvent.SET_TEACHING_MODE, handler.onTeacherSetTeachingMode);
    handlers.set(TeacherWSEvent.UPDATE_STUDENT_BADGE, handler.onTeacherUpdateStudentBadge);
    handlers.set(TeacherWSEvent.UPDATE_BLACK_OUT, handler.onTeacherUpdateBlackOut);
    handlers.set(TeacherWSEvent.START_LESSON_PLAN, handler.onTeacherStartLessonPlan);
    handlers.set(TeacherWSEvent.END_LESSON_PLAN, handler.onTeacherEndLessonPlan);
    handlers.set(TeacherWSEvent.SET_ITEM_CONTENT_LESSON_PLAN, handler.onTeacherSetLessonPlanItemContent);
    handlers.set(TeacherWSEvent.CLEAR_RAISING_HAND, handler.onTeacherClearRaisingHand);
    handlers.set(TeacherWSEvent.UPDATE_LESSON_ACTION, handler.onTeacherUpdateClassAction);
    handlers.set(TeacherWSEvent.DESIGNATE_INTERACTIVE, handler.onTeacherDesignateTarget);
    handlers.set(TeacherWSEvent.UPDATE_INTERACTIVE, handler.onTeacherUpdateDesignateTarget);
    handlers.set(TeacherWSEvent.EVENT_STUDENT_UPDATE_ANSWER_LIST, handler.onStudentUpdateAnswers);
    handlers.set(TeacherWSEvent.EVENT_UPDATE_POINTER, handler.onTeacherSetPointer);
    handlers.set(TeacherWSEvent.EVENT_ANNOTATION_UPDATE_MODE, handler.onTeacherUpdateAnnotationMode);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_ADD_BRUSHSTROKE, handler.onTeacherAddBrush);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_CLEAR_BRUSHSTROKE, handler.onTeacherClearAllBrush);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_DELETE_FABRIC, handler.onTeacherDeleteFabric);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_DELETE_SHAPE, handler.onTeacherDeleteShape);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_DELETE_BRUSHSTROKE, handler.onTeacherDeleteBrush);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_SET_STICKER, handler.onTeacherSetStickers);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_CLEAR_STICKER, handler.onTeacherClearStickers);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_SET_WHITEBOARD, handler.onTeacherSetWhiteboard);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_SET_MEDIA_STATE, handler.onTeacherSetMediaState);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_SET_CURRENT_TIME_MEDIA, handler.onTeacherSetCurrentTimeMedia);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_DRAW_LASER_PEN, handler.onTeacherDrawLaser);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_UPDATE_STUDENT_PALETTE, handler.onTeacherToggleStudentPalette);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_ANNOTATION_SET_BRUSHSTROKE, handler.onTeacherAddShape);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_DRAW_PENCIL_PEN, handler.onTeacherDrawPencil);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_SET_ONE_TO_ONE, handler.onTeacherSetOneToOne);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_FAIL_SET_ONE_TO_ONE, handler.onTeacherFailSetOneToOne);
    handlers.set(TeacherWSEvent.TEACHER_CREATE_FABRIC_OBJECT, handler.onTeacherCreateFabricObject);
    handlers.set(TeacherWSEvent.TEACHER_MODIFY_FABRIC_OBJECT, handler.onTeacherModifyFabricObject);
    handlers.set(TeacherWSEvent.TEACHER_RESET_ZOOM, handler.onTeacherResetZoom);
    handlers.set(TeacherWSEvent.TEACHER_ZOOM_SLIDE, handler.onTeacherZoomSlide);
    handlers.set(TeacherWSEvent.TEACHER_MOVE_ZOOMED_SLIDE, handler.onTeacherMoveZoomedSlide);
    handlers.set(TeacherWSEvent.EVENT_UPDATE_SHAPE, handler.onToggleTarget);
    handlers.set(RoomWSEvent.EVENT_ROOM_INFO, handler.onRoomInfo);
    handlers.set(TeacherWSEvent.TEACHER_UPDATE_SESSION_LESSON_AND_UNIT, handler.onTeacherUpdateSessionLessonAndUnit);
    handlers.set(TeacherWSEvent.HELPER_REQUEST_JOIN_CLASS, handler.onHelperRequestJoinClass);
    handlers.set(TeacherWSEvent.CANCEL_REQUEST_TO_JOIN_SESSION, handler.onHelperCancelRequestJoinClass);
    handlers.set(HelperWSEvent.JOIN_CLASS, handler.onHelperJoinedClass);
    handlers.set(HelperWSEvent.EXIT_CLASS, handler.onHelperExitClass);
    handlers.set(HelperWSEvent.HELPER_DISCONNECT_CLASS, handler.onHelperDisconnectClass);
    handlers.set(TeacherWSEvent.TEACHER_HIDE_HELPER_VIDEO, handler.onTeacherHideHelperVideo);
    handlers.set(TeacherWSEvent.TEACHER_SHOW_HELPER_VIDEO, handler.onTeacherShowHelperVideo);
    handlers.set(TeacherWSEvent.TEACHER_REMOVE_HELPER, handler.onTeacherRemoveHelper);
	// Obsolete
    handlers.set(TeacherWSEvent.HELPER_TOGGLE_CAMERA, handler.onHelperToggleCamera);
	// Obsolete
    handlers.set(TeacherWSEvent.HELPER_TOGGLE_MICRO, handler.onHelperToggleMicro);
	// Obsolete
    handlers.set(TeacherWSEvent.EVENT_TEACHER_REQUEST_STUDENT_RE_CONNECT_VIDEO, handler.onTeacherRequestStudentReConnectVideo);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_TOGGLE_INDEPENDENT_MODE, handler.onTeacherToggleIndependentMode);
    handlers.set(TeacherWSEvent.EVENT_STUDENT_SELECT_ITEM_IN_INDEPENDENT, handler.onStudentSelectItemInIndependentMode);
    handlers.set(TeacherWSEvent.EVENT_HELPER_BECOME_TEACHER, handler.onHelperBecomeTeacher);
    handlers.set(TeacherWSEvent.EVENT_HELPER_TOGGLE_TEACHER_AUDIO, handler.onOneToOneWithHelperIgnoreTeacherVoice);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_SCROLL_PDF, handler.onTeacherScrollPdf);
    handlers.set(TeacherWSEvent.EVENT_TOGGLE_TEAM_MODE, handler.onToggleTeamMode);
    handlers.set(TeacherWSEvent.EVENT_EDIT_TEAM, handler.onEditTeam);
    handlers.set(TeacherWSEvent.EVENT_RESET_TEAM, handler.onResetTeam);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_UPDATE_CANVAS_OBJECT, handler.onTeacherUpdateCanvasObject);
    handlers.set(TeacherWSEvent.EVENT_TEACHER_TOGGLE_STUDENT_MEDIA_DEVICES, handler.onTeacherToggleStudentMediaDevices);
    handlers.forEach((func, key) => {
      this.hubConnection.on(key, (payload: any) => {
        func(payload);
      });
      this._listener[key] = key;
    });
  }
}
