export interface CallingState {
  callingUserIds: string[];
  videoUserIds: string[];
  audioUserIds: string[];
  enabledAgoraNoiseCancelling: boolean;
  postLocalUserReconnectedIntervalId?: number;
}

export const initCallingModuleState = () => ({
  callingUserIds: [],
  videoUserIds: [],
  audioUserIds: [],
  enabledAgoraNoiseCancelling: true,
  postLocalUserReconnectedIntervalId: undefined,
});

const state: CallingState = initCallingModuleState();

export default state;
