import { AgoraClient, AgoraClientOptions, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { VCPlatform } from "@/store/app/state";
import { Logger } from "@/utils/logger";
import { GLSocketClient, WSEventHandler } from "@/ws";
import { ZoomClient, ZoomClientOptions } from "@/zoom";

export interface RoomOptions {
  agora?: AgoraClientOptions;
  zoom?: ZoomClientOptions;
}

export enum MediaDeviceStatus {
  DEFAULT = 0,
  LOCKED = 1,
  UNLOCKED = 2,
}

export interface MediaDevice {
  id: string;
  name: string;
}

export interface MediaStateInterface {
  devices: Array<MediaDevice>;
  activeDeviceId?: string;
  status: MediaDeviceStatus;
}

export abstract class BaseRoomManager<T extends GLSocketClient> {
  agoraClient!: AgoraClient;
  zoomClient!: ZoomClient;
  options!: RoomOptions;
  WSClient!: T;
  agoraEventHandler!: AgoraEventHandler;

  abstract join(options: {
    classId: string;
    studentId?: string;
    teacherId?: string;
    camera?: boolean;
    microphone?: boolean;
    isMirror?: boolean;
    isRemoteMirror?: boolean;
    callingEventHandlers: AgoraEventHandler;
  }): Promise<any>;

  registerEventHandler(eventHandler: WSEventHandler) {
    return this.WSClient.registerEventHandler(eventHandler);
  }
  isJoinedRoom() {
    if (store.getters.platform === VCPlatform.Agora) {
      this.agoraClient?.joined;
    } else {
      this.zoomClient?.joined;
    }
  }
  setCamera(options: { enable: boolean; videoEncoderConfigurationPreset?: string }) {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.setCamera(options);
    } else {
      return this.zoomClient.setCamera(options);
    }
  }

  setMicrophone(options: { enable: boolean }) {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.setMicrophone(options);
    } else {
      return this.zoomClient.setMicrophone(options);
    }
  }

  updateCameraDevice() {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.updateCameraDevice();
    } else {
      return this.zoomClient.updateCameraDevice();
    }
  }

  updateMicrophoneDevice() {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.updateMicrophoneDevice();
    } else {
      return this.zoomClient.updateMicrophoneDevice();
    }
  }

  async updateSpeakerDevice() {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.updateSpeakerDevice();
    } else {
      return this.zoomClient.updateSpeakerDevice();
    }
  }

  async updateAudioAndVideoFeed(cameras: Array<string>, audios: Array<string>) {
    this.agoraClient.updateAudioAndVideoFeed(cameras, audios);
  }

  // Obsolete
  async checkAndReconnectVideo() {
    return this.agoraClient.checkAndReconnectCamera();
  }

  async studentOneAndOne(cameras: Array<string>, audios: Array<string>) {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.updateAudioAndVideoFeed(cameras, audios);
    }
  }

  getBandwidth() {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.getBandwidth();
    }
  }

  async close() {
    if (store.getters.platform === VCPlatform.Agora) {
      return this.agoraClient.reset();
    } else {
      return this.zoomClient.reset();
    }
  }
}
