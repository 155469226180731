export enum StudentWSEvent {
  JOIN_CLASS = "2106",
  // TODO: Remove in re-engineering both BE and FE
  STREAM_CONNECT = "2107",
  // Obsolete
  MUTE_AUDIO = "2108",
  // Obsolete
  MUTE_VIDEO = "2109",
  LEAVE = "2110",
  DISCONNECT = "2300",

  STUDENT_RAISING_HAND = "2105",
  // TODO: Remove in re-engineering both BE and FE
  STUDENT_LIKE = "2104",
  EVENT_STUDENT_ANSWER_TARGET = "2102",
  EVENT_STUDENT_ANSWER_CORRECT = "2101",
  // Obsolete
  EVENT_TEACHER_ANSWER_TARGET = "1109",
  // TODO: Remove in re-engineering both BE and FE
  EVENT_STUDENT_UPDATE_MEDIA_INFO = "2103",
  // TODO: Remove in re-engineering both BE and FE
  EVENT_STUDENT_SEND_UNITY = "2400",
  EVENT_STUDENT_UPDATE_SHAPE_LIST = "2111",
  EVENT_STUDENT_DRAWS_LINE = "2112",

  EVENT_UPDATE_SHAPE = "1122",
  JOIN_SESSION_ON_DIFFERENT_DEVICE = "2115",
}
