import { Parent } from "@/models";
import { AccessibleSchoolQueryParam, RemoteTeachingService, ScheduleParam, FetchSessionDtoResModel, TeacherService } from "@/services";
import { Logger } from "@/utils/logger";
import { All } from "@/views/teacher-calendar/teacher-calendar";
import { ActionContext, ActionTree } from "vuex";
import { CalendarFilter, TeacherState } from "./state";
import { defineVuexName, vuexName, VuexNames } from "@/store/utils";

const actions: ActionTree<TeacherState, any> = {
  async setInfo({ dispatch, commit }, payload: Parent) {
    await dispatch("setAcceptPolicy");
    commit("setInfo", payload);
  },
  async loadAllClassesSchedulesAllSchool({ commit }: ActionContext<TeacherState, any>, payload: { schoolId: string }) {
    const response = await TeacherService.getAllClassesOfAllSchools();
    commit("setClassesSchedulesAllSchool", response);
  },
  async loadAllClassesSchedules({ commit }: ActionContext<TeacherState, any>, payload: { schoolId: string; deviceId: string }) {
    const response = await TeacherService.getAllClassesSchedule(payload.schoolId);
    commit("setClassesSchedules", response);
  },
  async clearSchedules({ commit }: ActionContext<TeacherState, any>) {
    commit("clearCalendarSchedule");
  },
  async clearAllClassesSchedulesAllSchool({ commit, state }: ActionContext<TeacherState, any>) {
    commit("clearAllClassesSchedulesAllSchool");
  },
  async loadSchedules(
    { commit, state }: ActionContext<TeacherState, any>,
    payload: {
      schoolId: string;
      classId: string;
      groupId: string;
      startDate: string;
      endDate: string;
    },
  ) {
    if (!state.info) return;
    const response = await TeacherService.getScheduleCalendar(
      payload.schoolId === All ? "" : payload.schoolId,
      payload.classId,
      payload.groupId,
      payload.startDate,
      payload.endDate,
    );
    commit("setCalendarSchedule", response);
  },
  async loadAllSchedules({ commit }: ActionContext<TeacherState, any>, payload: { startDate: string; endDate?: string }) {
    const response = await TeacherService.getAllScheduleCalendar(payload.startDate, payload.endDate ?? "");
    commit("setCalendarSchedule", response);
  },
  async skipSchedule({ commit, state }: ActionContext<TeacherState, any>, payload: { day: string; customId: string; data: ScheduleParam }) {
    const response = await TeacherService.skipSchedule(payload.data);
    if (response)
      commit("updateCalendarSchedule", {
        ...payload,
        scheduleId: response.scheduleId,
      });
  },
  async createSchedule(
    { commit, state }: ActionContext<TeacherState, any>,
    payload: {
      day: string;
      className: string;
      groupName: string;
      data: ScheduleParam;
      id?: string;
    },
  ) {
    const response = await TeacherService.createSchedule(payload.data);
    if (response) {
      payload.id = response;
      commit("updateCalendarSchedule", payload);
    }
  },
  async updateSchedule({ commit }: ActionContext<TeacherState, any>, payload: { day: string; groupName: string; data: ScheduleParam }) {
    const response = await TeacherService.updateSchedule(payload.data);
    if (response) commit("updateCalendarSchedule", payload);
  },
  async deleteSchedule({ commit }: ActionContext<TeacherState, any>, payload: { day: string; data: { scheduleId: string; type: string } }) {
    const response = await TeacherService.deleteSchedule(payload.data.scheduleId);
    if (response) commit("updateCalendarSchedule", payload);
  },
  async loadAccessibleSchools({ commit }: ActionContext<TeacherState, any>, payload: AccessibleSchoolQueryParam) {
    try {
      const response = await TeacherService.getAccessibleSchools(payload);
      commit("setSchools", response);
    } catch (error) {
      Logger.error(error);
    }
  },
  async setAcceptPolicy({ commit }) {
    const policyResponse: FetchSessionDtoResModel = await RemoteTeachingService.acceptPolicy("teacher");
    commit("setAcceptPolicy", policyResponse.data);
  },
  setCurrentSchool({ commit }, p: string) {
    commit("setCurrentSchool", p);
  },
  async setClassesSchedulesBySchools({ commit, state }) {
    for (const school of state.schools) {
      const response = await TeacherService.getAllClassesSchedule(school.id);
      response.forEach((item) => {
        item.schoolId = school.id;
      });
      commit("setClassesSchedulesBySchools", response);
    }
  },
  async getGroupStudents({ commit }, p: { classId: string; groupId: string }) {
    const response = await TeacherService.getGroupStudents(p.classId, p.groupId);
    commit("setClassSetUpStudents", response);
  },
  clearClassesSchedulesBySchools({ commit }) {
    commit("clearClassesSchedulesBySchools");
  },
  async setClassGroup({ commit }) {
    const response = await TeacherService.getClassGroup();
    if (response.length) {
      response.forEach((item) => {
        if (item.groups.length) {
          item.groups.sort((a, b) => (a.groupName > b.groupName ? 1 : -1));
        }
      });
      commit("setClassGroup", response);
    }
  },
  setCalendarFilter({ commit }, p: CalendarFilter) {
    commit("setCalendarFilter", p);
  },
  setIsHelper({ commit }, payload: boolean) {
    commit("setIsHelper", payload);
  },
  setIsHelperTakingOver({ commit }, payload: boolean) {
    commit("setIsHelperTakingOver", payload);
  },
  async [defineVuexName(VuexNames.TEACHER.DISPATCHES.UPDATE_ONGOING_SESSION)]({ commit }) {
    try {
      const resp = await RemoteTeachingService.fetchOngoingSession();
      commit(vuexName(VuexNames.TEACHER.COMMITS.SET_ONGOING_SESSION, false), resp.data);
    } catch (e) {
      Logger.error(e);
    }
  },
};

export default actions;
