import { AgoraClient, AgoraEventHandler } from "@/agora";
import { store } from "@/store";
import { VCPlatform } from "@/store/app/state";
import { StudentWSClient } from "@/ws";
import { BaseRoomManager, RoomOptions } from "./base.manager";
import { UserModel } from "@/models/user.model";
import { RoomModel } from "@/models";
import { getDeviceId } from "@/utils/utils";
import { isUserPublishedVideo } from "@/store/calling/utils";

export class StudentRoomManager extends BaseRoomManager<StudentWSClient> {
  constructor(options: RoomOptions) {
    super();
    this.options = options;
    if (options.agora) {
      this.agoraClient = new AgoraClient(options.agora);
    }
    this.WSClient = new StudentWSClient({
      url: `${process.env.VUE_APP_REMOTE_TEACHING_SERVICE}/${process.env.VUE_APP_REMOTE_TEACHING_HUB}`,
      reConnectedCallback: async (newConnectionId: string) => {
        await this.handleSuccessfulReconnection(newConnectionId);
      },
      studentId: store.getters["studentRoom/student"]?.id,
    });
    this.WSClient.init();
  }

  async handleSuccessfulReconnection(newConnectionId: string) {
    await store.dispatch("studentRoom/joinWSRoom", true);
    const refreshSessionData = async () => {
      const { getters, dispatch } = store;
      const user = getters["studentRoom/user"] as UserModel;
      const room = getters["studentRoom/info"] as RoomModel;
      await dispatch("studentRoom/initClassRoom", {
        classId: room.classInfo.classId,
        userId: user.id,
        userName: user.name,
        studentId: user.id,
        role: "student",
        deviceId: getDeviceId(),
        callFirstTime: false,
      });
    };
    await refreshSessionData();
  }

  async join(options: {
    classId?: string;
    studentId?: string;
    teacherId?: string;
    idOne?: string;
    isMirror?: boolean;
    isRemoteMirror?: boolean;
    callingEventHandlers: AgoraEventHandler | null;
  }) {
    if (!options.studentId || !options.classId) throw new Error("Missing Params");
    await this.WSClient.connect();
    if (store.getters.platform === VCPlatform.Agora) {
      await this.agoraClient.joinRTCRoom(options, false, async () => await this.reconnectAgoraRoom());
    }
  }
  async reconnectAgoraRoom() {
    if (!this.agoraClient.joinRoomOptions) return;

    const { id } = store.getters["studentRoom/student"] || {};
    const isVideoPublished = isUserPublishedVideo({ userId: id ?? "", gettersOrRootGetters: store.getters });
    const isAudioPublished = isUserPublishedVideo({ userId: id ?? "", gettersOrRootGetters: store.getters });
    const options = { ...this.agoraClient.joinRoomOptions, camera: isVideoPublished, microphone: isAudioPublished };

    await this.agoraClient.joinRTCRoom(options, true);
  }

  async manualReconnectAgoraRoom() {
    await this.reconnectAgoraRoom();
  }

  async close(end?: boolean) {
    await this.WSClient.disconnect();
    if (store.getters.platform === VCPlatform.Agora) {
      if (end) {
        await this.agoraClient?.reset();
      } else {
        await this.agoraClient.leaveChannel();
      }
    }
  }
}
