import { MediaStatus } from "@/models";
import { GLSocketClient } from "../base";
import { StudentWSCommand as WSCmd } from "./command";
import { ToggleTargetModel } from "@/ws";
import { store } from "@/store";
import { getDeviceId } from "@/utils/utils";

interface JoinRoomParams {
  sessionId: string;
  studentId: string;
  resolution: string;
  deviceId: string;
  isMuteAudio?: boolean;
  isMuteVideo?: boolean;
}

export interface ChangeIndependentItemParams {
  studentId: string;
  currentItemId: string;
  sessionId: string;
  unit: number;
  lesson: number;
  progress: number;
}

export interface StudentSendRequestMuteMediaDevice {
  isMute: boolean;
  studentId: string;
  sessionId: string;
}

export interface StudentSendRequestRaisingHand {
  isRaisedHand: boolean;
  studentId: string;
  sessionId: string;
}

interface StudentRequestModel {
  sessionId: string;
  studentId: string;
}

interface StudentHeartBeatModel extends StudentRequestModel{
  deviceId: string
}

export class StudentWSClient extends GLSocketClient {
  get studentRequestModel(): StudentRequestModel {
    return {
      studentId: store.getters["studentRoom/student"]?.id,
      sessionId: store.getters["classTeaching/getSessionId"],
    };
  }
  sendRequestJoinRoom(
    sessionId: string,
    studentId: string,
    deviceId: string,
    isMuteAudio = MediaStatus.noStatus,
    isHideVideo = MediaStatus.noStatus,
  ) {
    const resolution = screen.width * window.devicePixelRatio + "x" + screen.height * window.devicePixelRatio;
    const params: JoinRoomParams = {
      sessionId: sessionId,
      studentId: studentId,
      resolution,
      deviceId,
    };
    if (isMuteAudio !== MediaStatus.noStatus) {
      let status = false;
      if (isMuteAudio === MediaStatus.mediaLocked) {
        status = true;
      }
      params.isMuteAudio = status;
    }
    if (isHideVideo !== MediaStatus.noStatus) {
      let status = false;
      if (isHideVideo === MediaStatus.mediaLocked) {
        status = true;
      }
      params.isMuteVideo = status;
    }
    return this.send(WSCmd.JOIN_SESSION, params);
  }
  sendRequestMuteVideo(payload: StudentSendRequestMuteMediaDevice) {
    return this.invoke(WSCmd.TOGGLE_VIDEO, payload);
  }
  sendRequestMuteAudio(payload: StudentSendRequestMuteMediaDevice) {
    return this.invoke(WSCmd.TOGGLE_AUDIO, payload);
  }
  sendRequestRaisingHand(payload: StudentSendRequestRaisingHand) {
    return this.send(WSCmd.RAISE_HAND, payload);
  }
  sendRequestLike() {
    return this.send(WSCmd.LIKE, {});
  }
  sendRequestAnswer(payload: { x: number; y: number; contentId: string }) {
    return this.invoke(WSCmd.ANSWER, payload);
  }
  sendRequestUnity(message: string) {
    return this.send(WSCmd.STUDENT_ACTION_GAME, message);
  }
  sendRequestStudentLeaveClass(sessionId?: string, studentId?: string) {
    return this.invoke(WSCmd.LEAVE_SESSION, { sessionId, studentId });
  }
  sendRequestToggleShape(payload: ToggleTargetModel) {
    const { tag, visible, sessionId } = payload;
    return this.send(WSCmd.TOGGLE_SHAPE, { studentId: payload.userId, tag, visible, sessionId });
  }
  sendRequestChangeIndependentItem(payload: ChangeIndependentItemParams) {
    return this.invoke(WSCmd.CHANGE_INDEPENDENT_ITEM, payload);
  }
  sendStudentHeartBeat() {
    const model: StudentHeartBeatModel = { deviceId: getDeviceId(),...this.studentRequestModel };
    return this.send(WSCmd.STUDENT_HEART_BEAT, model);
  }
}
