export enum TeacherWSEvent {
  JOIN_CLASS = "1203",
  // Obsolete
  STREAM_CONNECT = "1204",
  // Obsolete
  MUTE_AUDIO = "1205",
  // Obsolete
  MUTE_VIDEO = "1206",
  END_CLASS = "1211",
  DISCONNECT = "1300",
  SET_TEACHING_MODE = "1212",
  UPDATE_STUDENT_BADGE = "1202",
  UPDATE_BLACK_OUT = "1114",
  START_LESSON_PLAN = "1115",
  END_LESSON_PLAN = "1116",
  SET_ITEM_CONTENT_LESSON_PLAN = "1130",
  CLEAR_RAISING_HAND = "1112",
  UPDATE_LESSON_ACTION = "1113",
  DESIGNATE_INTERACTIVE = "1111",
  UPDATE_INTERACTIVE = "1110",
  EVENT_STUDENT_UPDATE_ANSWER_LIST = "2100",
  EVENT_UPDATE_POINTER = "1108",
  EVENT_ANNOTATION_UPDATE_MODE = "1105",
  EVENT_TEACHER_ANNOTATION_ADD_BRUSHSTROKE = "1107",
  EVENT_TEACHER_ANNOTATION_UPDATE_BRUSHS = "1104",
  EVENT_TEACHER_ANNOTATION_SET_BRUSHSTROKE = "1103",
  EVENT_TEACHER_ANNOTATION_CLEAR_BRUSHSTROKE = "1106",
  EVENT_TEACHER_ANNOTATION_DELETE_BRUSHSTROKE = "1102",
  EVENT_TEACHER_ANNOTATION_SET_STICKER = "1101",
  EVENT_TEACHER_ANNOTATION_CLEAR_STICKER = "1100",
  EVENT_TEACHER_SEND_UNITY = "1400",
  EVENT_TEACHER_SET_ONE_TO_ONE = "1213",
  EVENT_TEACHER_FAIL_SET_ONE_TO_ONE = "1226",
  EVENT_TEACHER_SET_WHITEBOARD = "1214",
  EVENT_TEACHER_DRAW_LASER_PEN = "1118",
  EVENT_TEACHER_UPDATE_STUDENT_PALETTE = "1120",
  EVENT_TEACHER_ADD_SHAPE = "1215",
  TEACHER_CREATE_FABRIC_OBJECT = "1217",
  TEACHER_MODIFY_FABRIC_OBJECT = "1218",
  TEACHER_ZOOM_SLIDE = "1219",
  TEACHER_MOVE_ZOOMED_SLIDE = "1220",
  TEACHER_RESET_ZOOM = "1221",
  EVENT_TEACHER_DRAW_PENCIL_PEN = "1126",
  EVENT_TEACHER_RESET_PALETTE_ALL_STUDENT = "1127",
  EVENT_UPDATE_SHAPE = "1122",
  TEACHER_UPDATE_SESSION_LESSON_AND_UNIT = "1222",
  EVENT_TEACHER_ANNOTATION_DELETE_FABRIC = "1128",
  EVENT_TEACHER_ANNOTATION_DELETE_SHAPE = "1129",
  EVENT_TEACHER_SET_MEDIA_STATE = "1223",
  EVENT_TEACHER_SET_CURRENT_TIME_MEDIA = "1224",
  EVENT_TEACHER_SCROLL_PDF = "1303",

  // RELATING TO HELPER
  HELPER_REQUEST_JOIN_CLASS = "1500",
  CANCEL_REQUEST_TO_JOIN_SESSION = "1509",
  TEACHER_SHOW_HELPER_VIDEO = "TH001",
  TEACHER_HIDE_HELPER_VIDEO = "TH002",
  TEACHER_REMOVE_HELPER = "TH003",
  // Obsolete
  HELPER_TOGGLE_CAMERA = "1505",
  // Obsolete
  HELPER_TOGGLE_MICRO = "1504",
  EVENT_TEACHER_REQUEST_STUDENT_RE_CONNECT_VIDEO = "1225",
  EVENT_TEACHER_TOGGLE_INDEPENDENT_MODE = "1302",
  EVENT_STUDENT_SELECT_ITEM_IN_INDEPENDENT = "2114",
  EVENT_HELPER_BECOME_TEACHER = "1508",
  EVENT_HELPER_TOGGLE_TEACHER_AUDIO = "1510",
  EVENT_TOGGLE_TEAM_MODE = "TM001",
  EVENT_EDIT_TEAM = "TM002",
  EVENT_RESET_TEAM = "TM003",
  EVENT_TEACHER_UPDATE_CANVAS_OBJECT = "TM004",
  EVENT_TEACHER_TOGGLE_STUDENT_MEDIA_DEVICES = "TM005",
}
