import moment from "moment/moment";
import { ContentService, ContentSignatureItem, FetchContentSignatureRespModel } from "@/services";
import { LocalStorageHandler } from "@/utils/storage";
import { CONTENT_SIGNATURE } from "@/utils/constant";
import { Logger } from "@/utils/logger";
export const DEFAULT_CONTENT_BLOCK_ITEM_NAME = "Content";
export const DEFAULT_TEACHING_ACTIVITY_BLOCK_ITEM_NAME = "Teaching Activity";
export const DEFAULT_RESOLUTION = "1024X722";
export const DEFAULT_ALTERNATE_MEDIA_BLOCK_ITEM_NAME = "Alternate Media";
const CONTENT_SIGNATURE_EXPIRED_THRESHOLD = 5; // in minutes;
export interface ContentSignatureStorageModel extends FetchContentSignatureRespModel {
  countryCode: string;
}
export interface GetContentSignatureOptions {
  userCountryCode: string;
}

export const getContentSignature = (options: GetContentSignatureOptions): Promise<FetchContentSignatureRespModel> => {
  const { userCountryCode } = options;
  const signatureStorage = LocalStorageHandler.getInstance<ContentSignatureStorageModel>(CONTENT_SIGNATURE);
  const hasExpiredSignature = (data: ContentSignatureItem[]): boolean => {
    const currentDateTime = moment().utc();
    return data.some((item) => {
      if (item && item.signature) {
        // get the expiration time from the signature query string
        const expirationMatch = item.signature.match(/se=([^&]+)/);
        if (expirationMatch) {
          const expirationTimeString = decodeURIComponent(expirationMatch[1]);
          const expirationTime = moment(expirationTimeString, moment.ISO_8601, true).utc();
          if (expirationTime.isValid()) {
            const timeDifference = expirationTime.diff(currentDateTime, "minutes");
            // If there are only 5 minutes or less left, the signature is considered expired and should be queried again
            return timeDifference <= CONTENT_SIGNATURE_EXPIRED_THRESHOLD;
          }
        }
      }
      return false;
    });
  };
  const hasSameCountryCode = (storageCountryCode: string): boolean => {
    return storageCountryCode === userCountryCode;
  };
  const validateSignatureResponse = (resp: FetchContentSignatureRespModel | null): void => {
    if (!(resp?.url && resp.data.length > 0)) {
      throw new Error("Invalid or empty signature response.");
    }
  };
  const getValidSignature = async (): Promise<FetchContentSignatureRespModel> => {
    const retrievedSignatureData = signatureStorage.get();
    if (
      typeof retrievedSignatureData?.countryCode === "string" &&
      hasSameCountryCode(retrievedSignatureData.countryCode) &&
      retrievedSignatureData?.data &&
      retrievedSignatureData.data.length > 0 &&
      !hasExpiredSignature(retrievedSignatureData.data)
    ) {
      return retrievedSignatureData;
    }
    try {
      const resp = await ContentService.fetchContentSignature();
      validateSignatureResponse(resp);
      const model = { ...resp, countryCode: userCountryCode };
      signatureStorage.set(model);
      return model;
    } catch (error) {
      Logger.error(error);
      throw new Error("Failed to fetch or validate signature.");
    }
  };
  return getValidSignature();
};
