import { MediaStatus } from "@/models";
import { CAMERA_ID_KEY, MICROPHONE_ID_KEY, SPEAKER_ID_KEY } from "@/utils/constant";

export type LayoutType = "" | "full" | "main";
export enum AppView {
  Authorized = 1,
  UnAuthorized = 2,
  NotFound = 3,
  Blank = 4,
}

export enum UserRole {
  UnConfirm = 0,
  Teacher = 1,
  Student = 2,
}

// TODO: Remove in re-engineering
export enum VCPlatform {
  Agora = 1,
  Zoom = 2,
}

export interface AppState {
  layout: LayoutType;
  appView: AppView;
  toast: ToastData;
  isMuteAudio: number;
  isHideVideo: number;
  cameraDeviceId: string;
  microphoneDeviceId: string;
  speakerDeviceId: string;
  userRole: UserRole;
  platform: VCPlatform;
  singalrInited: boolean;
  teacherMessageVersion: number;
  checkMessageVersionTimer: number;
  isLowBandWidth: boolean;
  replacedClassId: string;
  havePermissionCamera: boolean;
  havePermissionMicrophone: boolean;
  havePermissionSpeaker: boolean;
}
export interface ToastData {
  message: string;
  isPlayingSound?: boolean;
  bigIcon?: any;
  isMedal?: boolean;
}
const cameraDeviceId = localStorage.getItem(CAMERA_ID_KEY);
const microphoneDeviceId = localStorage.getItem(MICROPHONE_ID_KEY);
const speakerDeviceId = localStorage.getItem(SPEAKER_ID_KEY);
const state: AppState = {
  layout: "main",
  appView: AppView.Blank,
  toast: { message: "", isPlayingSound: false },
  isMuteAudio: MediaStatus.noStatus,
  isHideVideo: MediaStatus.noStatus,
  cameraDeviceId: cameraDeviceId || "",
  microphoneDeviceId: microphoneDeviceId || "",
  speakerDeviceId: speakerDeviceId || "",
  userRole: UserRole.UnConfirm,
  platform: VCPlatform.Agora,
  singalrInited: false,
  teacherMessageVersion: 0,
  checkMessageVersionTimer: -1,
  isLowBandWidth: false,
  replacedClassId: "",
  havePermissionCamera: true,
  havePermissionMicrophone: true,
  havePermissionSpeaker: true,
};

export default state;
