<template>
  <div class="my-4 flex-1 relative" ref="scrollElement">
    <div class="absolute inset-0 overflow-auto">
      <div v-if="!totalOnlineStudents" class="w-full mt-2 text-center border border-dotted rounded-xl p-4 border-slate-400 text-slate-500">
        {{ MsgNoStudentOnline }}
      </div>
      <div
        v-else
        ref="studentGallery"
        :id="'student-list'"
        class="grid gap-x-2 gap-y-2"
        :class="[oneWithTeacherStudentId ? 'grid-cols-1' : studentLayoutClass]"
      >
        <StudentCard
          v-for="(student, index) in topStudents"
          :key="student.id"
          :scaleOption="scaleVideoOption"
          :student="student"
          :setModeOne="true"
          :focusStudentId="focusedStudent"
          :class="getTransformOriginClass(index)"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" src="./student-all.ts"></script>
