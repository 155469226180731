import { HelperLocales, TeacherClassError, TeacherClassLocale } from "@/locales/localeid";
import { AnnotationMode, HelperModel, RoomModel, StudentModel, TeacherModel } from "@/models";
import router from "@/router";
import { RemoteTeachingService, ToggleStudentMediaDevicesAsync } from "@/services";
import { IndependentStudentItem } from "@/services/independent";
import { store } from "@/store";
import { FabricUpdateType, UserShape } from "@/store/annotation/state";
import { ExposureStatus, TeachingActivityTarget } from "@/store/lesson/state";
import {
  OneToOneModeResponse,
  OnTeacherClearRaisingHand,
  OnTeacherSetLessonPlanItemContent,
  OnTeacherToggleStudentPalette,
  OnTeacherUpdateStudentBadgeItem,
  TeacherSetMediaStateReceiveResponse,
} from "@/store/room/interface";
import { MIN_ZOOM_RATIO, SESSION_ID } from "@/utils/constant";
import { Logger } from "@/utils/logger";
import { Paths } from "@/utils/paths";
import { HelperJoiningNotify } from "@/views/teacher-class/components";
import { notification } from "ant-design-vue";
import { h } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { ActionContext } from "vuex";
import { ClassViewFromValue, HelperRequestJoinClassPayload } from "../interface";
import { ClassActionFromValue } from "../student/state";
import { TeacherRoomState } from "./state";
import { TeachingMode } from "@/store/teaching/interfaces";
import { WSEventHandler } from "@/ws";
import { CanvasObjectModel } from "@/hooks/use-send-websocket-msg";
import { ObjectTypes, OperationTypes } from "@/utils/utils";
import { IEditLine } from "@/store/annotation/mutations";
import { vuexName, VuexNames } from "@/store/utils";
import { calculatePlayedTime } from "@/utils/convertDuration";
import { notifySameUserJoined } from "@/utils/notifications";

export const useTeacherRoomWSHandler = ({ commit, dispatch, state, rootGetters }: ActionContext<TeacherRoomState, any>): WSEventHandler => {
  const isHelper = rootGetters["teacher/isHelper"];
  const executeIfIsHelper = (fn: (...args: any[]) => any) => {
    return (...args: any[]) => {
      const isHelper = rootGetters["teacher/isHelper"];
      if (isHelper) {
        return fn(...args);
      }
    };
  };
  const executeIfSenderNotMe = (fn: (...args: any[]) => any) => {
    return async (...args: any[]) => {
      const payload = args[0];
      const userId: string | undefined = rootGetters["auth/userId"];
      const senderId = payload?.userId;
      if (userId && senderId && userId === senderId) return;
      await fn(...args);
    };
  };

  const timestamps = {
    onTeacherSetLessonPlanItemContentTimestamp: 0,
  };
  const handler = {
    onStudentJoinClass: async (payload: StudentModel) => {
      const { id, isRaisingHand, isPalette } = payload;
      commit("setStudentConnectionStatus", {
        id: payload.id,
        status: payload.connectionStatus,
      });
      commit("updateRaisingHand", {
        id,
        isRaisingHand: isRaisingHand,
      });
      commit("updateIsPalette", {
        id,
        isPalette,
      });
      await dispatch("updateAudioAndVideoFeed", {});
    },
    // Obsolete
    onStudentStreamConnect: (payload: any) => {
      //
    },
    // Obsolete
    onStudentMuteAudio: async (payload: StudentModel) => {
      //
    },
    // Obsolete
    onStudentMuteVideo: async (payload: StudentModel) => {
      commit("setStudentVideo", {
        id: payload.id,
        enable: !payload.isMuteVideo,
      });
    },
    onStudentLeave: async (payload: StudentModel) => {
      const idOne = rootGetters["teacherRoom/getStudentModeOneId"];
      if (isHelper && idOne && idOne === payload.id) {
        dispatch("forceOutHelperOneToOne");
      }
      commit("studentLeftClass", { id: payload.id });
      const student = state.students.find((student) => student.id === payload.id);
      if (!state.isSessionEnded && student && student.englishName) {
        notification.warn({
          message: fmtMsg(TeacherClassLocale.StudentLeftClass, {
            studentName: student.englishName,
          }),
        });
      }
    },
    onStudentSendUnity: async (payload: any) => {
      await dispatch(
        "unity/setStudentMessage",
        { message: payload },
        {
          root: true,
        },
      );
    },
    onTeacherJoinClass: async (payload: TeacherModel) => {
      const teacherId = state.teacher?.id;
      if (!isHelper) {
        const currentSignalRId = state.teacher?.signalRConnectionId;
        if (currentSignalRId && currentSignalRId !== payload.signalrConnectId) {
          //* This teacher has just logged in another device
          await dispatch("resetClass", false);
          notifySameUserJoined();
          await router.push(Paths.Home);
          return;
        } else {
          commit("setTeacherSignalRConnectionId", payload.signalrConnectId);
        }
      }
    },
    onTeacherStreamConnect: (payload: any) => {
      //   Logger.log(payload);
    },
    onTeacherToggleStudentPalette: async (payload: OnTeacherToggleStudentPalette) => {
      commit("setStudentPalette", payload);
    },
    onTeacherEndClass: async () => {
      const sessionId = rootGetters["classTeaching/getSessionId"];
      localStorage.setItem(SESSION_ID, sessionId);
      if (isHelper) {
        commit("teacher/setIsHelper", false, { root: true });
        commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
        await dispatch("resetClass");
      }
      await dispatch("modal/showCustomerFeedbackSurvey", sessionId, { root: true });
    },
    onTeacherSetTeachingMode: (payload: number) => {
      if (!rootGetters["teacher/isHelper"]) return;
      commit("setClassView", {
        classView: ClassViewFromValue(payload),
      });
    },
    onTeacherUpdateStudentBadge: (payload: OnTeacherUpdateStudentBadgeItem[]) => {
      commit("setStudentsBadge", payload);
    },
    onTeacherUpdateBlackOut: (payload: any) => {
      commit("lesson/setIsBlackOut", { IsBlackOut: payload.isBlackOut }, { root: true });
    },
    onTeacherStartLessonPlan: (id: string) => {
      commit("lesson/setCurrentExposure", { id }, { root: true });
    },
    onTeacherEndLessonPlan: (payload: { contentId: string; needUpdateRemainingTime: boolean }) => {
      if (payload.needUpdateRemainingTime) {
        commit("lesson/setExposureStatus", { id: payload.contentId, status: ExposureStatus.COMPLETED }, { root: true });
        const playedTime = calculatePlayedTime(rootGetters["lesson/exposures"]);
        commit("lesson/setPlayedTime", { time: playedTime }, { root: true });
      } else {
        commit("lesson/setExposureStatus", { id: payload.contentId }, { root: true });
      }
    },
    onTeacherSetLessonPlanItemContent: async ({ exposureItemId, timestamp }: OnTeacherSetLessonPlanItemContent) => {
      if (isHelper) {
        const previousTimestamp = timestamps.onTeacherSetLessonPlanItemContentTimestamp;
        if (previousTimestamp && timestamp < previousTimestamp) return;
      }
      if (isHelper && !exposureItemId) {
        commit("lesson/endCurrentContent", {}, { root: true });
      }
      commit("whiteboard/setShouldFetchMediaState", false, { root: true });
      commit("lesson/setCurrentExposureItemMedia", { id: exposureItemId }, { root: true });
      commit("lesson/clearZoomState", null, { root: true });
      commit("lesson/setPdfScrollProgress", 0, { root: true });
      commit("whiteboard/resetState", null, { root: true });
      await dispatch("annotation/setClearBrush", {}, { root: true });
      await dispatch("annotation/clearPencilPath", null, { root: true });
      commit("annotation/setDrawings", [], { root: true });
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, { root: true });
      commit("setWhiteboard", false);
      commit(vuexName(VuexNames.LESSON.COMMITS.SET_VISIBLE_TARGET_TAGS), [], { root: true });
    },
    onStudentRaisingHand: async (student: StudentModel) => {
      commit("setStudentRaisingHand", {
        id: student.id,
        raisingHand: student.isRaisingHand,
      });
    },
    onTeacherClearRaisingHand: ({ id }: OnTeacherClearRaisingHand) => {
      commit("setStudentRaisingHand", {
        id: id,
        raisingHand: false,
      });
    },
    onTeacherUpdateClassAction: (payload: { action: number }) => {
      commit(
        "teacherRoom/setClassAction",
        {
          action: ClassActionFromValue(payload.action),
        },
        { root: true },
      );
    },
    onTeacherDesignateTarget: async (payload: any) => {
      await dispatch("interactive/setInfo", payload, { root: true });
    },
    onTeacherUpdateDesignateTarget: async (payload: any) => {
      await dispatch("interactive/setInfo", payload, { root: true });
    },
    onStudentAnswerSelf: (payload: any) => {
      //   Logger.log(payload);
    },
    onStudentAnswerAll: async (payload: any) => {
      await dispatch("interactive/setRevealedTarget", payload.id, {
        root: true,
      });
    },
    onStudentUpdateAnswers: async (payload: any) => {
      await dispatch("interactive/setUpdateStudentsAnswerForTeacher", payload, {
        root: true,
      });
    },
    onTeacherSetPointer: async (payload: any) => {
      if (!isHelper) return;

      const isPointer = rootGetters["annotation/isPointerMode"];
      if (!isPointer) await dispatch("annotation/setMode", { mode: AnnotationMode.PointerMode }, { root: true });
      await dispatch("annotation/setPointer", payload, {
        root: true,
      });
    },
    onTeacherUpdateAnnotationMode: async (payload: any) => {
      if (!isHelper) return;
      await dispatch("annotation/setMode", { mode: payload }, { root: true });
    },
    onTeacherAddBrush: async (payload: any) => {
      await dispatch("annotation/addShape", payload, {
        root: true,
      });
    },
    onTeacherClearAllBrush: executeIfIsHelper(async (payload: any) => {
      commit("annotation/setDrawings", [], { root: true });
      await dispatch("annotation/setClearBrush", {}, { root: true });
      await dispatch("annotation/clearPencilPath", null, { root: true });
    }),
    async onTeacherDeleteBrush(payload: any) {
      if (!isHelper) return;
      await dispatch("annotation/setDeleteBrush", {}, { root: true });
    },
    onTeacherSetStickers(payload: any) {
      //   Logger.log(payload);
    },
    onTeacherClearStickers(payload: any) {
      //   Logger.log(payload);
    },
    onTeacherSendUnity(payload: any) {
      //   Logger.log(payload);
    },
    onTeacherSetOneToOne: async (payload: OneToOneModeResponse) => {
      store.commit("classTeaching/setMyOneAndOneData", null, { root: true });
      const { enable, studentOneToOneId } = payload;
      const isHelper = rootGetters["teacher/isHelper"];
      if (isHelper) {
        if (enable) {
          await dispatch("teacherRoom/setStudentOneWithAnotherTeacherId", { id: studentOneToOneId }, { root: true });
        } else {
          await dispatch("teacherRoom/setStudentOneWithAnotherTeacherId", { id: "" }, { root: true });
        }
        await dispatch("updateAudioAndVideoFeed", {});
        return;
      }
      if (enable) {
        //** Handle when enter oneone mode */
        /* Best case scenario is we have to keep all the state remaining*/
        await dispatch("teacherRoom/setStudentOneId", { id: studentOneToOneId }, { root: true });
        await dispatch("updateAudioAndVideoFeed", {});
      } else {
        try {
          //** Handle when exit oneone mode */
          const { data } = await RemoteTeachingService.getMinimalSessionDto(rootGetters["classTeaching/getSessionId"], studentOneToOneId);
          await dispatch("teams/getTeams", null, { root: true });
          commit("teams/setIsTeamMode", data.isTeamMode, { root: true });
          await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
          await dispatch("updateAudioAndVideoFeed", {});
          commit("whiteboard/setShouldFetchMediaState", true, { root: true });
          commit("setClassView", {
            classView: ClassViewFromValue(data.teachingMode),
          });
          commit("lesson/endCurrentContent", {}, { root: true });
          commit(
            "lesson/setCurrentExposure",
            {
              id: data.exposureSelected,
              preventSelectFirstSlideAutomatically: !!data.itemContentSelected,
            },
            { root: true },
          );
          if (data.itemContentSelected) {
            commit("lesson/setCurrentExposureItemMedia", { id: data.itemContentSelected }, { root: true });
          }
          commit("teacherRoom/setWhiteboard", data.isShowWhiteBoard, {
            root: true,
          });
          await dispatch("annotation/setTeacherBrushes", data.drawing?.brushstrokes, { root: true });
          await dispatch("annotation/setTeacherAddShape", { teacherShapes: data.drawing?.shapes }, { root: true });
          await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), data.drawing?.visibleShapes, {
            root: true,
          });
          await dispatch("lesson/setZoomRatio", data.ratio, {
            root: true,
          });
          await dispatch("lesson/setPdfScrollProgress", data.ratioScrollPdf, {
            root: true,
          });
          if (data.position) {
            await dispatch("lesson/setImgCoords", { x: data.position.x, y: data.position.y }, { root: true });
          }
          //* update student's independent mode progress
          if (data.independentMode) {
            commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
            commit("teacherTeaching/setIndependentListStudentItems", rootGetters["teacherTeaching/getIndependentListStudentItems"], { root: true });
          } else {
            commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
            commit("teacherTeaching/setIndependentListStudentItems", null, { root: true });
          }
          commit("annotation/setDrawings", data.drawing?.fabrics ?? [], { root: true });
          commit("setStudentPalette", {
            id: studentOneToOneId,
            isPalette: data.isPalette,
          });
        } catch (err) {
          Logger.log(err);
        }
      }
    },
    onTeacherFailSetOneToOne: async (payload: any) => {
      const isHelper = rootGetters["teacher/isHelper"];
      if (isHelper) return;

      notification.error({
        message: fmtMsg(TeacherClassError.FailSetOneOneMode),
      });
    },
    onTeacherSetWhiteboard: async (payload: RoomModel) => {
      commit("teacherRoom/setWhiteboard", payload, { root: true });
    },
    onTeacherSetMediaState: async (payload: TeacherSetMediaStateReceiveResponse) => {
      if (!isHelper) return;
      const { startMediaAt, duration, pauseMediaAt } = payload;
      commit("whiteboard/setMediaState", { startMediaAt, duration, pauseMediaAt }, { root: true });
    },
    onTeacherSetCurrentTimeMedia: async (payload: any) => {
      commit("teacherRoom/setCurrentTimeMedia", payload, { root: true });
    },

    onStudentSetBrushstrokes: async (payload: any) => {
      await dispatch("annotation/setStudentAddShape", { studentShapes: payload }, { root: true });
    },
    onTeacherAddShape: async (payload: Array<UserShape>) => {
      await dispatch("annotation/setTeacherAddShape", { teacherShapes: payload }, { root: true });
    },
    onStudentDrawsLine: async (payload: string) => {
      //
    },
    onTeacherCreateFabricObject: async (payload: any) => {
      if (!isHelper) return;
      await dispatch(
        "annotation/setLastFabricUpdated",
        {
          type: FabricUpdateType.CREATE,
          data: payload,
        },
        { root: true },
      );
    },
    onTeacherModifyFabricObject: async (payload: any) => {
      if (!isHelper) return;
      await dispatch(
        "annotation/setLastFabricUpdated",
        {
          type: FabricUpdateType.MODIFY,
          data: payload,
        },
        { root: true },
      );
    },
    onToggleTarget: executeIfSenderNotMe(async (payload: TeachingActivityTarget) => {
      await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGET_BY_FROM_MESSAGE), payload, { root: true });
    }),
    onTeacherUpdateSessionLessonAndUnit: async (teacherId: any) => {
      const userId: string | undefined = rootGetters["auth/userId"];
      if (userId === teacherId) return;
      await dispatch("setLessonAndUnit", null);
    },
    onRoomInfo: async (payload: RoomModel) => {
      //
    },
    onTeacherZoomSlide: async (p: { ratio: number; position: { x: number; y: number } }) => {
      if (!isHelper) return;
      await dispatch("lesson/setZoomRatio", p.ratio, { root: true });
      await dispatch("lesson/setImgCoords", { x: p.position.x, y: p.position.y }, { root: true });
    },
    onTeacherMoveZoomedSlide: async (
      p: {
        x: number;
        y: number;
        viewPortX: number;
        viewPortY: number;
      } | null,
    ) => {
      if (!isHelper) return;
      await dispatch("lesson/setImgCoords", p ? { x: p.x, y: p.y } : undefined, { root: true });
    },
    onTeacherResetZoom: async (p: any) => {
      await dispatch("lesson/setZoomRatio", MIN_ZOOM_RATIO, {
        root: true,
      });
    },
    onTeacherDrawPencil: async (p: string) => {
      if (!isHelper) return;
      await dispatch("annotation/setDrawPencil", p, { root: true });
    },
    onTeacherDrawLaser: async (p: any) => {
      if (!isHelper) return;
      await dispatch("annotation/setDrawLaser", p, { root: true });
    },
    onTeacherDeleteFabric: async (payload: any) => {
      if (!isHelper) return;
      if (payload.fabricId) {
        commit("annotation/setLastFabricIdDeleted", payload.fabricId, { root: true });
      }
      await dispatch("annotation/setDeleteFabric", {}, { root: true });
    },
    onTeacherDeleteShape: async (payload: any) => {
      if (!isHelper) return;
      await dispatch("annotation/setDeleteShape", {}, { root: true });
    },
    onHelperRequestJoinClass: async (payload: HelperRequestJoinClassPayload) => {
      const shouldIgnore = rootGetters["teacher/getIgnoreHelperRequestJoinClass"];
      if (shouldIgnore) return;
      const { name, id, deviceId } = payload;
      const cb = () => {
        commit("teacher/setIgnoreHelperRequestJoinClass", true, { root: true });
      };
      notification.info({
        key: id,
        message: fmtMsg(HelperLocales.NotifyTitle),
        description: h(HelperJoiningNotify, {
          helperName: name,
          helperId: id,
          deviceId,
          cb,
        }),
        duration: null,
        class: "helper-joining-notify",
        placement: "topLeft",
      });
    },
    onHelperCancelRequestJoinClass: async (payload: { id: string }) => {
      notification.close(payload.id);
      commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
    },
    onHelperJoinedClass: async (payload: HelperModel) => {
      if (isHelper) {
        const currentSignalRId = state.helper?.signalRConnectionId;
        if (currentSignalRId && currentSignalRId !== payload.signalrConnectId) {
          //* This helper has just logged in another device
          await dispatch("resetClass", false);
          notifySameUserJoined();
          await router.push(Paths.Home);
          return;
        }
      }

      commit("setHelperInfo", payload);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onHelperExitClass: async () => {
      dispatch("forceOutHelperOneToOne");
      commit("setHelperInfo", undefined);
      commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onStudentDisconnected: async (payload: StudentModel) => {
      //
    },
    onTeacherDisconnect: (payload: any) => {
      //OK
    },
    onHelperDisconnectClass: async () => {
      //
    },
    onTeacherHideHelperVideo: async () => {
      commit("setHelperVideoStatus", false);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherShowHelperVideo: async () => {
      commit("setHelperVideoStatus", true);
      await dispatch("updateAudioAndVideoFeed", {});
    },
    onTeacherRemoveHelper: async () => {
      if (isHelper) {
        commit("teacher/setIsHelper", false, { root: true });
      } else {
        commit("teacher/setIgnoreHelperRequestJoinClass", false, { root: true });
        notification.info({
          message: fmtMsg(TeacherClassLocale.RemoveHelperSuccessfully),
        });
      }
    },
    // Obsolete
    onHelperToggleCamera: async (isOff: boolean) => {
      commit("setHelperCameraStatus", isOff);
    },
    // Obsolete
    onHelperToggleMicro: async (isOff: boolean) => {
      commit("setHelperMicroStatus", isOff);
    },
    // Obsolete
    onTeacherRequestStudentReConnectVideo: async () => {
      //
    },
    onHelperSetOneToOne: async (payload: OneToOneModeResponse) => {
      store.commit("classTeaching/setMyOneAndOneData", null, { root: true });
      const { enable, studentOneToOneId } = payload;
      const isHelper = rootGetters["teacher/isHelper"];
      if (!isHelper) {
        if (enable) {
          await dispatch("teacherRoom/setStudentOneWithAnotherTeacherId", { id: payload.studentOneToOneId }, { root: true });
        } else {
          await dispatch("teacherRoom/setStudentOneWithAnotherTeacherId", { id: "" }, { root: true });
        }
        await dispatch("updateAudioAndVideoFeed", {});
        return;
      }
      if (enable) {
        //** Handle when enter oneone mode */
        /* Best case scenario is we have to keep all the state remaining*/
        await dispatch("teacherRoom/setStudentOneId", { id: studentOneToOneId }, { root: true });
        commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", true, { root: true });
        await dispatch("updateAudioAndVideoFeed", {});
      } else {
        try {
          //** Handle when exit oneone mode */
          const { data } = await RemoteTeachingService.getMinimalSessionDto(rootGetters["classTeaching/getSessionId"], studentOneToOneId);
          await dispatch("teams/getTeams", null, { root: true });
          commit("teams/setIsTeamMode", data.isTeamMode, { root: true });
          await dispatch("teacherRoom/setStudentOneId", { id: "" }, { root: true });
          commit("classTeaching/setOneToOneWithHelperIgnoreTeacherVoice", false, { root: true });
          await dispatch("updateAudioAndVideoFeed", {});
          commit("whiteboard/setShouldFetchMediaState", true, { root: true });
          commit("setClassView", {
            classView: ClassViewFromValue(data.teachingMode),
          });
          commit("lesson/setPlayedTime", { time: data.playedTime }, { root: true });
          commit("lesson/endCurrentContent", {}, { root: true });
          commit(
            "lesson/setCurrentExposure",
            {
              id: data.exposureSelected,
              preventSelectFirstSlideAutomatically: true,
            },
            { root: true },
          );
          commit("lesson/setCurrentExposureItemMedia", { id: data.itemContentSelected ?? "" }, { root: true });
          commit("teacherRoom/setWhiteboard", data.isShowWhiteBoard, {
            root: true,
          });
          await dispatch("annotation/setTeacherBrushes", data.drawing?.brushstrokes, { root: true });
          await dispatch("annotation/setTeacherAddShape", { teacherShapes: data.drawing?.shapes }, { root: true });
          await dispatch("annotation/setFabricsInDrawing", data.drawing?.fabrics, { root: true });
          await dispatch(vuexName(VuexNames.LESSON.DISPATCHES.PROCESS_VISIBLE_TARGETS_DATA_FROM_API), data.drawing?.visibleShapes, {
            root: true,
          });
          await dispatch("lesson/setZoomRatio", data.ratio, {
            root: true,
          });
          await dispatch("lesson/setPdfScrollProgress", data.ratioScrollPdf, {
            root: true,
          });
          if (data.position) {
            await dispatch("lesson/setImgCoords", { x: data.position.x, y: data.position.y }, { root: true });
          }
          //* update student's independent mode progress
          if (data.independentMode) {
            commit("classTeaching/setMode", TeachingMode.Independent, { root: true });
            commit("teacherTeaching/setIndependentListStudentItems", rootGetters["teacherTeaching/getIndependentListStudentItems"], { root: true });
          } else {
            commit("classTeaching/setMode", TeachingMode.Normal, { root: true });
            commit("teacherTeaching/setIndependentListStudentItems", null, { root: true });
          }
          commit("annotation/setDrawings", data.drawing?.fabrics ?? [], { root: true });
        } catch (err) {
          Logger.log(err);
        }
      }
    },
    onHelperFailSetOneToOne: async (payload: any) => {
      const isHelper = rootGetters["teacher/isHelper"];
      if (!isHelper) return;
      notification.error({
        message: fmtMsg(TeacherClassError.FailSetOneOneMode),
      });
    },
    onTeacherToggleIndependentMode: async (firstItemId: string | null) => {
      if (!isHelper) return;
      await dispatch("teacherTeaching/helperToggleIndependentMode", firstItemId, { root: true });
    },
    onStudentSelectItemInIndependentMode: (payload: IndependentStudentItem) => {
      commit("teacherTeaching/updateIndependentListStudentItems", payload, { root: true });
    },
    onHelperBecomeTeacher: (payload: any) => {
      dispatch("forceOutHelperOneToOne");
      //
    },
    onOneToOneWithHelperIgnoreTeacherVoice: (ignore: boolean) => {
      //
    },
    onTeacherScrollPdf: async (progress: number) => {
      if (!isHelper) return;
      commit("lesson/setPdfScrollProgress", progress, { root: true });
    },
    onToggleTeamMode: (payload: boolean) => {
      commit("teams/setIsTeamMode", payload, { root: true });
    },
    onEditTeam: async () => {
      await dispatch("teams/getTeams", null, { root: true });
    },
    onResetTeam: async () => {
      await dispatch("teams/getTeams", null, { root: true });
    },
    onTeacherUpdateCanvasObject: async (payload: CanvasObjectModel) => {
      const isHelper = rootGetters["teacher/isHelper"];
      if (!isHelper) return;
      const { type, id, operation, metadata } = payload;
      if (type === ObjectTypes.Line) {
        commit("annotation/removePolyline", id, { root: true });
        if (operation === OperationTypes.Remove) {
          // use dispatch because we have two different variables for lines: lines and lines for one-one mode,
          // and we can't determine which one to use in mutation file
          await dispatch("annotation/removeLine", id, { root: true });
        } else if (operation === OperationTypes.Edit && metadata) {
          // use dispatch because we have two different variables for lines: lines and lines for one-one mode,
          // and we can't determine which one to use in mutation file
          const payload: IEditLine = { objectId: id, metadata };
          await dispatch("annotation/editLine", payload, { root: true });
        }
      }
      commit("object/setNextObjectToBeUpdated", payload, { root: true });
    },
    onTeacherToggleStudentMediaDevices: async (payload: ToggleStudentMediaDevicesAsync) => {
      //
    },
    onJoinSessionOnDifferentDevice: async () => {
      //
    },
  };
  return handler;
};
