import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "absolute top-3 left-1/2 -translate-x-1/2 text-slate-600 flex gap-x-1" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuestionMarkCircleIcon = _resolveComponent("QuestionMarkCircleIcon")!
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!
  const _component_MicrophoneIcon = _resolveComponent("MicrophoneIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isRaisingHand)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickClearRaisingHand && _ctx.onClickClearRaisingHand(...args))),
          class: "h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
        }, [
          _createVNode(_component_QuestionMarkCircleIcon, { class: "h-[18px] w-[18px]" })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickToggleVideo && _ctx.onClickToggleVideo(...args))),
      disabled: _ctx.isLoadingCamera,
      class: "h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:cursor-wait"
    }, [
      (_ctx.isLoadingCamera)
        ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
            key: 0,
            class: "animate-spin"
          }))
        : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isVideoPublished ? _ctx.VideoCameraIcon : _ctx.VideoCameraSlashIcon), {
            key: 1,
            class: "h-[18px] w-[18px]"
          }))
    ], 8, _hoisted_2),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onClickToggleAudio && _ctx.onClickToggleAudio(...args))),
      disabled: _ctx.isLoadingMicrophone,
      class: "h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:cursor-wait"
    }, [
      (_ctx.isLoadingMicrophone)
        ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
            key: 0,
            class: "animate-spin"
          }))
        : (_openBlock(), _createBlock(_component_MicrophoneIcon, {
            key: 1,
            "icon-class": "h-[18px] w-[18px]",
            enabled: _ctx.isAudioPublished
          }, null, 8, ["enabled"]))
    ], 8, _hoisted_3),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClickTogglePalette && _ctx.onClickTogglePalette(...args))),
      disabled: _ctx.isPaletteDisabled || _ctx.isLoadingPalette,
      class: "h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors disabled:bg-slate-300"
    }, [
      (_ctx.isLoadingPalette)
        ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
            key: 0,
            class: "animate-spin"
          }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.paletteIcon,
            alt: "",
            class: _normalizeClass([_ctx.isPaletteDisabled && 'opacity-60', "h-[18px] w-[18px]"])
          }, null, 10, _hoisted_5))
    ], 8, _hoisted_4),
    _createElementVNode("button", {
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onClickAddBadge && _ctx.onClickAddBadge(...args))),
      disabled: _ctx.isLoadingBadge,
      class: "relative h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
    }, [
      (_ctx.isLoadingBadge)
        ? (_openBlock(), _createBlock(_component_LoadingOutlined, {
            key: 0,
            class: "animate-spin"
          }))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("img", {
              src: require('@/assets/teacher-class/sticker-star-small.svg'),
              alt: "",
              class: "h-5 w-5"
            }, null, 8, _hoisted_7),
            _createElementVNode("span", {
              class: _normalizeClass([[_ctx.student.badge < 10 ? 'text-sm' : _ctx.student.badge < 100 ? 'text-xs' : 'text-[10px]'], "absolute left-1/2 top-1/2 text-white -translate-x-1/2 -translate-y-1/2"])
            }, _toDisplayString(_ctx.student.badge), 3)
          ], 64))
    ], 8, _hoisted_6),
    (_ctx.isShowExpandIcon && !_ctx.focusDisabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleExpand && _ctx.handleExpand(...args))),
          class: "h-8 w-8 bg-slate-100/80 flex items-center justify-center rounded-md hover:bg-white hover:shadow transition-colors"
        }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.focusedStudent ? _ctx.ArrowsPointingInIcon : _ctx.ArrowsPointingOutIcon), { class: "h-[18px] w-[18px]" }))
        ]))
      : _createCommentVNode("", true)
  ]))
}