import { TeacherClassGallery } from "@/locales/localeid";
import { StudentState } from "@/store/room/interface";
import { useElementSize } from "@vueuse/core";
import { computed, defineComponent, provide, ref, PropType } from "vue";
import { fmtMsg } from "vue-glcommonui";
import { useStore } from "vuex";
import StudentCard from "../student-card/student-card.vue";
import { useManipulateTeamState } from "@/hooks/use-team";
import { sortBy } from "lodash";

// const ANT_PROGRESS_CLASS = "ant-progress-bg";
export default defineComponent({
  props: {
    students: {
      required: true,
      type: Object as PropType<StudentState[]>,
    },
  },
  components: {
    StudentCard,
  },
  setup(props) {
    const MsgNoStudentOnline = computed(() => fmtMsg(TeacherClassGallery.NoStudentJoinClass));
    const BREAKPOINTS = 450;
    const { getters } = useStore();
    const studentGallery = ref<HTMLElement>();
    const { width: studentGalleryWidth } = useElementSize(studentGallery);
    const { isTeamMode, findTeamByStudentId } = useManipulateTeamState();
    const topStudents = computed<StudentState[]>(() => {
      const listStudents = props.students.slice(0, 12).filter(({ id }) => getters["calling/checkCallingHasUserById"](id));
      return isTeamMode
        ? sortBy(listStudents, [
            function (s) {
              return findTeamByStudentId(s.id)?.order;
            },
          ])
        : listStudents;
    });
    const oneWithTeacherStudentId = computed(() => getters["teacherRoom/getStudentModeOneId"]);
    const totalOnlineStudents = computed(() => topStudents.value.length);
    const studentPerRow = computed(() => {
      if (totalOnlineStudents.value <= 1 || (totalOnlineStudents.value <= 6 && studentGalleryWidth.value < BREAKPOINTS)) return 1;
      if (totalOnlineStudents.value <= 6 || (totalOnlineStudents.value > 6 && studentGalleryWidth.value < BREAKPOINTS)) return 2;
      return 3;
    });
    const studentLayoutClass = computed(() => {
      switch (studentPerRow.value) {
        case 3:
          return "grid-cols-3";
        case 2:
          return "grid-cols-2";
        default:
          return "grid-cols-1";
      }
    });
    const scaleVideoOption = computed(() => {
      if (studentPerRow.value === 1) return 1;
      if (totalOnlineStudents.value <= 3) return 1.6;
      if (totalOnlineStudents.value <= 6) return 1.4;
      return 2;
    });
    const focusedStudent = ref<string>("");
    const updateFocusStudent = (studentId?: string) => {
      if (studentId) {
        return (focusedStudent.value = studentId);
      }
      focusedStudent.value = "";
    };
    const getTransformOriginClass = (index: number) => {
      const numberOfRows = Math.ceil(totalOnlineStudents.value / studentPerRow.value);
      const rowIndex = Math.ceil((index + 1) / studentPerRow.value);
      const colIndex = (index + 1) % studentPerRow.value;
      let vertical = "";
      let horizontal = "";
      if (colIndex === 1) {
        horizontal = "-left";
      } else if (colIndex === 0) {
        horizontal = "-right";
      }
      if (rowIndex === 1) {
        vertical = "-top";
      } else if (rowIndex === numberOfRows) {
        vertical = "-bottom";
      }
      if (vertical || horizontal) {
        return "origin" + vertical + horizontal;
      }
      return "";
    };
    provide("updateFocusStudent", updateFocusStudent);
    return {
      topStudents,
      oneWithTeacherStudentId,
      focusedStudent,
      totalOnlineStudents,
      scaleVideoOption,
      MsgNoStudentOnline,
      studentPerRow,
      studentLayoutClass,
      studentGallery,
      // expandedWrapperStyle,
      // isUsingAgora,
      // canvasRef,
      // canvasListStudentVideosRef,
      getTransformOriginClass,
    };
  },
});
